"use client";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {
  ICardCarousel,
  ICardCarouselFields,
  IContentSection,
  IContentSectionFields,
  IRecipeSection,
  IRecipeSectionFields,
} from "../../@types/generated/contentful";
import CustomContainer from "../container";
import ContentSection from "../content-section";
import RecipeSection from "../content-section/recipe";
import RecipeSectionPrint from "../content-section/recipe-section-print";
import CardCarousel from "../content-section/section-card-carousel";

interface ContentSectionWrapperProps {
  section: IContentSection | ICardCarousel | IRecipeSection;
  isPrintView?: boolean;
}
const ContentSectionWrapper = (props: ContentSectionWrapperProps) => {
  const { section, isPrintView } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (section.sys.contentType.sys.id == "contentSection") {
    const fields = section.fields as IContentSectionFields;
    return isSmallScreen && fields.isHideOnMobile ? (
      <></>
    ) : (
      <CustomContainer
        backgroundColor={fields.backgroundColor}
        isStroke={fields.border}
        fullWidth={fields.fullWidth}
        key={`container-${fields.internal}`}
        lowTopPadding={fields.lowTopPadding}
        lowBottomPadding={fields.lowBottomPadding}
      >
        <ContentSection
          internal={fields.internal}
          isReversed={fields.isReversed}
          heading={fields.heading}
          layout={fields.layout}
          sectionItems={fields.sectionItems}
          level={fields.level}
          introText={fields.introText}
        />
      </CustomContainer>
    );
  } else if (section.sys.contentType.sys.id == "cardCarousel") {
    const fields = section.fields as ICardCarouselFields;
    return (
      <CustomContainer
        backgroundColor={fields.backgroundColor}
        isStroke={fields.border}
        fullWidth={fields.fullWidth}
        key={`container-${fields.internal}`}
        lowTopPadding={fields.lowTopPadding}
        lowBottomPadding={fields.lowBottomPadding}
      >
        <CardCarousel {...fields}></CardCarousel>
      </CustomContainer>
    );
  } else if (section.sys.contentType.sys.id == "recipeSection") {
    const fields = section.fields as IRecipeSectionFields;
    return isPrintView ? (
      <RecipeSectionPrint key={section.fields.internal} {...fields} />
    ) : (
      <RecipeSection key={section.fields.internal} {...fields} />
    );
  }
  return <></>;
};

export default ContentSectionWrapper;
