import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { EmblaCarouselType } from "embla-carousel";

import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

type PropType = PropsWithChildren<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>;

export const PrevButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;
  const theme = useTheme();
  return (
    <button
      style={{
        padding: 0,
        margin: 0,
        paddingBlock: 0,
        paddingInline: 0,
        borderRadius: "100%",
        display: "flex",
        border: "none",
        textAlign: "center",
        backgroundColor: "transparent",
        alignItems: "center",
        justifyContent: "center",
        transition: "opacity 0.3s ease-in-out",
        cursor: "pointer",
      }}
      {...restProps}
    >
      <ArrowBackIos
        sx={{
          color: theme.palette.softBlack.main,
          marginLeft: `${48 * 0.2}px`,
        }}
      />
      {children}
    </button>
  );
};

export const NextButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;
  const theme = useTheme();
  return (
    <button
      style={{
        padding: 0,
        margin: 0,
        paddingBlock: 0,
        paddingInline: 0,
        borderRadius: "100%",
        display: "flex",
        border: "none",
        textAlign: "center",
        backgroundColor: "transparent",
        alignItems: "center",
        justifyContent: "center",
        transition: "opacity 0.3s ease-in-out",
        cursor: "pointer",
      }}
      {...restProps}
    >
      <ArrowForwardIos
        sx={{
          color: theme.palette.softBlack.main,
          marginLeft: `${48 * 0.2}px`,
        }}
      />
      {children}
    </button>
  );
};
