"use client";

import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Image from "next/image";
import { useRouter } from "next/router";

import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";

import { IRecipeSectionFields } from "../../../@types/generated/contentful";
import PrintIcon from "../../../components/icons/Print.svg";
import BulbIcon from "../../../components/icons/recipes/Bulb.svg";
import ClockIcon from "../../../components/icons/recipes/Clock.svg";
import UnvertraeglichkeitenIcon from "../../../components/icons/recipes/Unvertraeglichkeiten.svg";
import EvergreenIcon from "../../../components/icons/recipes/badges/Evergreen.svg";
import FamilyIcon from "../../../components/icons/recipes/badges/Family.svg";
import FreezableIcon from "../../../components/icons/recipes/badges/Freeze.svg";
import GlutenIcon from "../../../components/icons/recipes/badges/Gluten.svg";
import MilchfreiIcon from "../../../components/icons/recipes/badges/Milchfrei.svg";
import SchlankboosterIcon from "../../../components/icons/recipes/badges/Schlankbooster.svg";
import VeganIcon from "../../../components/icons/recipes/badges/Vegan.svg";
import VegetarianIcon from "../../../components/icons/recipes/badges/Vegetarisch.svg";
import TauschIcon from "../../../components/icons/recipes/data.svg";
import CarbsIcon from "../../../components/icons/recipes/nutrition/carbsIcon.svg";
import FatIcon from "../../../components/icons/recipes/nutrition/fatIcon.svg";
import KcalIcon from "../../../components/icons/recipes/nutrition/kcalIcon.svg";
import ProteinIcon from "../../../components/icons/recipes/nutrition/proteinIcon.svg";
import { withHttps } from "../../../utils/helperFunctions";
import CustomContainer from "../../container";
import ContentSectionWrapper from "../../content-section-wrapper";
import PortionToggle from "./portionToggle";

const RecipeSection = ({
  internal,
  title,
  kcal,
  protein,
  fat,
  carbs,
  imageSquare,
  imageCredits,
  vegetarian,
  vegan,
  noGluten,
  noLactose,
  freezable,
  isFamily,
  evergreen,
  schlankbooster,
  prepTime,
  cookTime,
  cookTimeLabel = "Kochzeit",
  portions,
  portionLabel = "Portion",
  portionPluralLabel = "Portionen",
  ingredients = [],
  contentSections,
  stepsTitle,
  steps = [],
  extraTips = [],
}: IRecipeSectionFields) => {
  const theme = useTheme();
  const [currentPortions, setCurrentPortions] = useState(portions || 2);
  const ingredientList = ingredients as Ingredient[];
  const [checkedIngredients, setCheckedIngredients] = useState(
    ingredientList.map((ing) => false)
  );
  const router = useRouter();

  /**necessary to reset state when switching between recipe pages */
  useEffect(() => {
    return () => {
      setCurrentPortions(portions || 2);
      setCheckedIngredients(ingredientList.map((ing) => false));
    };
  }, []);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const upperColor = "#DDEDE7";
  const upperFontColor = "#97C4B0";

  const rowSpacing = 0;
  const colSpan = 12;
  const colSpanMd = 8;
  const offsetMd = 2;
  const gridSpacing = 3;

  const printIframe = (id: string) => {
    const iframe = (document as any).frames
      ? (document as any).frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  return (
    <Fragment key={router.asPath}>
      <iframe
        id="recipe"
        src={router.asPath + "/print"}
        style={{ display: "none" }}
      ></iframe>
      {/*Image + Title + Badges*/}
      <CustomContainer
        backgroundColor={upperColor}
        fullWidth={true}
        lowTopPadding={false}
        lowBottomPadding={false}
      >
        <Grid
          container
          columnSpacing={{ xs: 0, md: gridSpacing }}
          rowSpacing={{ xs: gridSpacing, md: rowSpacing }}
          sx={{
            justifyContent: "center",
          }}
        >
          <Grid item xs={colSpan} md={colSpanMd}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                mb: { xs: 3, sm: 4 },
              }}
            >
              <IconButton
                onClick={() => printIframe("recipe")}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PrintIcon />
              </IconButton>
            </Box>

            <Typography
              sx={{ pb: theme.spacing(2), textAlign: "center" }}
              component={"h1"}
              variant="h1"
            >
              {title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: { xs: "column", md: "row" },
                gap: { xs: theme.spacing(0.5), md: theme.spacing(2) },
                mt: { xs: theme.spacing(1), md: theme.spacing(2) },
                mb: { xs: theme.spacing(3), md: theme.spacing(5) },
              }}
            >
              <Typography
                sx={{ fontSize: { xs: 12, sm: 16 }, whiteSpace: "nowrap" }}
                variant="body1"
              >
                Nährwerte pro {portionLabel}:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: { xs: theme.spacing(0.5), md: theme.spacing(2) },
                }}
              >
                {kcal != undefined && (
                  <NutritionBox
                    icon={KcalIcon}
                    text={kcal + " kcal"}
                    color={theme.palette.softBlack.main}
                  />
                )}
                {(protein !== undefined ||
                  fat !== undefined ||
                  carbs !== undefined) && <NutritionVerticalDivider />}

                {protein != undefined && (
                  <NutritionBox
                    icon={ProteinIcon}
                    text={protein + " g Eiweiß"}
                    color={theme.palette.softBlack.main}
                  />
                )}
                {(fat !== undefined || carbs !== undefined) && (
                  <NutritionVerticalDivider />
                )}
                {fat != undefined && (
                  <NutritionBox
                    icon={FatIcon}
                    text={fat + " g Fett"}
                    color={theme.palette.softBlack.main}
                  />
                )}
                {carbs !== undefined && <NutritionVerticalDivider />}
                {carbs != undefined && (
                  <NutritionBox
                    icon={CarbsIcon}
                    text={carbs + " g Kohlenhydr."}
                    color={theme.palette.softBlack.main}
                  />
                )}
              </Box>
            </Box>

            <Box>
              <Box
                sx={{
                  position: "relative",
                  boxSizing: "border-box",
                }}
              >
                <Image
                  sizes={`(max-width: 900px) 100vw,
                (max-width: 1200px) 66vw, 1200px`}
                  layout="responsive"
                  width={960}
                  height={720}
                  style={{
                    borderRadius: theme.components?.Image.borderRadius,
                  }}
                  src={withHttps(imageSquare.fields.file.url)}
                  alt={""}
                />
              </Box>
              {imageCredits && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: theme.spacing(1),
                  }}
                >
                  <Typography variant="caption" sx={{ color: upperFontColor }}>
                    © {imageCredits}
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  mt: { xs: theme.spacing(3), md: theme.spacing(5) },
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: { xs: theme.spacing(1), md: theme.spacing(2) },
                }}
              >
                {evergreen && (
                  <RecipeBadge
                    color={"#6BB8D4"}
                    icon={EvergreenIcon}
                    name="Evergreen"
                  />
                )}
                {schlankbooster && (
                  <RecipeBadge
                    color={"#FF9CA9"}
                    icon={SchlankboosterIcon}
                    name="Schlankbooster"
                  />
                )}
                {vegetarian && (
                  <RecipeBadge
                    color={"#B9DDCF"}
                    icon={VegetarianIcon}
                    name="Vegetarisch"
                  />
                )}
                {vegan && (
                  <RecipeBadge
                    color={"#97C4B0"}
                    icon={VeganIcon}
                    name="Vegan"
                  />
                )}
                {noGluten && (
                  <RecipeBadge
                    color={"#D6BDAC"}
                    icon={GlutenIcon}
                    name="Glutenfrei"
                  />
                )}
                {noLactose && (
                  <RecipeBadge
                    color={"#E9D6CC"}
                    icon={MilchfreiIcon}
                    name="Milchfrei"
                  />
                )}
                {freezable && (
                  <RecipeBadge
                    color={"#0CAAED"}
                    icon={FreezableIcon}
                    name="Zum Einfrieren"
                  />
                )}
                {isFamily && (
                  <RecipeBadge
                    color={"#EFD280"}
                    icon={FamilyIcon}
                    name="Familienküche"
                  />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CustomContainer>
      {/*Ingredients*/}
      <CustomContainer
        backgroundColor={"none"}
        fullWidth={true}
        lowTopPadding={false}
        lowBottomPadding={!(contentSections && contentSections.length > 0)}
      >
        <Grid
          container
          columnSpacing={{ xs: 0, md: gridSpacing }}
          rowSpacing={{ xs: gridSpacing, md: rowSpacing }}
          sx={{
            justifyContent: "center",
          }}
        >
          <Grid item xs={colSpan} sm={colSpanMd}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                gap: { xs: theme.spacing(2), sm: 5, md: theme.spacing(10) },
                mb: { xs: theme.spacing(5), md: theme.spacing(10) },
              }}
            >
              <Box sx={{ display: "flex" }}>
                <TimeBox time={prepTime} text="Zubereitung" />
              </Box>

              <Box
                sx={{
                  width: "1px",
                  flexShrink: 0,
                  height: "64px",
                  backgroundColor: "#D1D1D1",
                }}
              />

              <Box sx={{ display: "flex" }}>
                <TimeBox time={cookTime} text={cookTimeLabel} />
              </Box>

              <Box
                sx={{
                  width: "1px",
                  flexShrink: 0,
                  height: "64px",
                  backgroundColor: "#D1D1D1",
                }}
              />
              <Box sx={{ display: "flex" }}>
                <TimeBox
                  time={(cookTime || 0) + (prepTime || 0)}
                  text="Gesamtzeit"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: theme.spacing(3), md: theme.spacing(4) },
              }}
            >
              <Typography
                variant="h2"
                sx={{ color: theme.palette.softBlack.dark }}
              >
                Deine Zutaten
              </Typography>
              <PortionToggle
                amount={currentPortions}
                label={portionLabel}
                labelPlural={portionPluralLabel}
                setAmount={setCurrentPortions}
              />
              <Typography
                variant="body1"
                sx={{ width: { xs: "100%", md: "60%" } }}
              >
                Du siehst die Zutaten für {currentPortions}{" "}
                {currentPortions == 1 ? portionLabel : portionPluralLabel}. Denk
                daran, auch bei der Zubereitung die angepassten Mengen zu
                verwenden.
              </Typography>
              <IngredientList
                ingredients={ingredients as Ingredient[]}
                portions={currentPortions}
                originalPortions={portions || 2}
                checkedIngredients={checkedIngredients}
                setCheckedIngredients={setCheckedIngredients}
              ></IngredientList>
            </Box>
          </Grid>
        </Grid>
      </CustomContainer>
      {contentSections?.map((cs, i) => (
        <ContentSectionWrapper
          key={`contentSection${i}`}
          section={cs}
        ></ContentSectionWrapper>
      ))}
      {/*Steps*/}
      <CustomContainer
        backgroundColor={"none"}
        fullWidth={true}
        lowTopPadding={!(contentSections && contentSections.length > 0)}
        lowBottomPadding={false}
      >
        <Grid
          container
          columnSpacing={{ xs: 0, md: gridSpacing }}
          rowSpacing={{ xs: gridSpacing, md: rowSpacing }}
          sx={{
            justifyContent: "center",
          }}
        >
          <Grid item xs={colSpan} sm={colSpanMd}>
            <RecipeSteps
              title={stepsTitle || ""}
              steps={steps as RecipeStep[]}
            ></RecipeSteps>
          </Grid>
        </Grid>
      </CustomContainer>
      {/*Tipps*/}
      {extraTips.length && (
        <CustomContainer
          backgroundColor={"#F5EBE5"}
          fullWidth={false}
          lowTopPadding={false}
          lowBottomPadding={false}
        >
          <Grid
            container
            columnSpacing={{ xs: 0, md: gridSpacing }}
            rowSpacing={{ xs: gridSpacing, md: rowSpacing }}
            sx={{
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} md={10}>
              <ExtraTips
                title={"Weitere Tipps für dich:"}
                extraTips={extraTips as ExtraTipp[]}
              ></ExtraTips>
            </Grid>
          </Grid>
        </CustomContainer>
      )}
    </Fragment>
  );
};

export default RecipeSection;

type ExtraTipp = {
  type:
    | "Evergreen-Extras"
    | "Tausch-Tipps"
    | "Unverträglichkeiten"
    | "Kids-Kniff"
    | "Schlankbooster-Drehs";
  texts: string[];
};
function ExtraTips(props: { title: string; extraTips: ExtraTipp[] }) {
  const theme = useTheme();
  const { extraTips, title } = props;

  const iconMap = {
    "Evergreen-Extras": { icon: EvergreenIcon, color: "#6BB8D4" },
    "Tausch-Tipps": { icon: TauschIcon, color: "#B2CCBC" },
    Unverträglichkeiten: { icon: UnvertraeglichkeitenIcon, color: "#D6BDAC" },
    "Schlankbooster-Drehs": { icon: SchlankboosterIcon, color: "#FF9CA9" },
    "Kids-Kniff": { icon: FamilyIcon, color: "#EFD280" },
  };
  const getIcon = (
    type:
      | "Evergreen-Extras"
      | "Tausch-Tipps"
      | "Unverträglichkeiten"
      | "Kids-Kniff"
      | "Schlankbooster-Drehs"
  ) => {
    return iconMap[type] || <></>;
  };

  const tippOrder = [
    "Evergreen-Extras",
    "Schlankbooster-Drehs",
    "Tausch-Tipps",
    "Unverträglichkeiten",
    "Kids-Kniff",
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(4),
      }}
    >
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        {title}
      </Typography>
      {tippOrder
        .filter((categoryName) =>
          extraTips.some(
            (tipp) => tipp.type == categoryName && tipp.texts.length
          )
        )
        .map((categoryName, i) => {
          const tipp = extraTips.find(
            (aTipp) => aTipp.type == categoryName
          ) as ExtraTipp;
          const { type, texts } = tipp;
          const { icon: TippIcon, color } = getIcon(type as any);
          return (
            <Box
              key={`tipp${i}`}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: { xs: theme.spacing(2), md: theme.spacing(4) },
              }}
            >
              <Box
                sx={{
                  backgroundColor: color,

                  borderRadius: "100%",
                  width: "52px",
                  minWidth: "52px",
                  height: "52px",
                  minHeight: "52px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: theme.palette.pureWhite.main,
                }}
              >
                <TippIcon
                  sx={{
                    width: "52px",
                    height: "52px",
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(1),
                }}
              >
                <Typography variant="h4">{type}</Typography>
                {texts.map((text, i) => (
                  <Typography key={"tippText" + type + i}>{text}</Typography>
                ))}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
}

function TimeBox(props: { time: number; text: string }) {
  const theme = useTheme();
  const { text, time } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: { xs: theme.spacing(1), md: theme.spacing(1.5) },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "16px", md: "26px" },
          fontWeight: 700,
          color: "black",
        }}
      >
        {text}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: { xs: theme.spacing(1), md: theme.spacing(2) },
          " svg": {
            width: "25px",
            height: "25px",
          },
        }}
      >
        <ClockIcon
          sx={{
            fill: "black",
            width: { xs: "20px", md: "25px" },
            height: { xs: "20px", md: "25px" },
          }}
        />
        <Typography
          sx={{ color: "black", fontSize: { xs: "16px", md: "20px" } }}
        >
          {time} Min.
        </Typography>
      </Box>
    </Box>
  );
}
type Ingredient = {
  name: string;
  plural?: string;
  amount: number;
  unit?: string;
};
function IngredientList(props: {
  originalPortions: number;
  ingredients: Ingredient[];
  checkedIngredients: boolean[];
  setCheckedIngredients: Dispatch<SetStateAction<boolean[]>>;
  portions: number;
}) {
  const {
    ingredients,
    portions,
    originalPortions,
    checkedIngredients,
    setCheckedIngredients,
  } = props;

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "flex-start",
        boxSizing: "border-box",
      }}
    >
      <List
        sx={{
          py: 0,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        {ingredients.map((ingredient, i) => {
          const { name, amount, unit, plural } = ingredient;
          return (
            <ListItem
              key={"ingredient" + i}
              sx={{
                pt: 0,
                pl: 0,
                display: "list-item",
              }}
            >
              <div
                onClick={() => {
                  setCheckedIngredients((prevState: boolean[]) => {
                    let arrCopy = prevState.slice();
                    arrCopy[i] = !arrCopy[i];
                    return arrCopy;
                  });
                }}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    pr: "28px",
                  }}
                >
                  <Checkbox
                    color="secondary"
                    sx={{
                      p: 0,
                      borderRadius: 0,
                    }}
                    checked={checkedIngredients[i]}
                  />
                </Box>
                <Box sx={{ position: "relative", pt: "2px" }}>
                  <Typography
                    component={"span"}
                    sx={{
                      background:
                        "linear-gradient(90deg, transparent, currentColor 0) no-repeat right center / " +
                        (checkedIngredients[i] ? "100%" : "0%") +
                        " .1em",
                      transition: "background-size .4s ease",
                      backgroundPositionX: checkedIngredients[i]
                        ? "right"
                        : "left",
                    }}
                  >
                    {`${
                      amount
                        ? decimalToCommonFraction(
                            (amount * portions) / originalPortions
                          )
                        : ""
                    } ${unit ? unit.trim() : ""} ${
                      (amount * portions) / originalPortions <= 1 || !plural
                        ? name
                        : plural
                    }`}
                  </Typography>
                </Box>
              </div>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

type RecipeStep = {
  text: string;
  tipps?: string;
  zutaten?: string[];
  duBrauchst?: string[];
};

function RecipeSteps(props: { title: string; steps: RecipeStep[] }) {
  const theme = useTheme();

  const { steps, title } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(4),
      }}
    >
      <Typography variant="h2">{title}</Typography>
      {steps.map((step, i) => {
        const { duBrauchst, text, tipps, zutaten } = step;
        return (
          <Box key={`step${i}`}>
            <Box
              sx={{
                backgroundColor: theme.palette.softGreen.lighter,
                p: theme.spacing(0.6, 2),
                borderRadius: "5px",
                width: "fit-content",
                mb: "5px",
              }}
            >
              <Typography
                sx={{ color: "black", fontSize: "20px", fontWeight: 700 }}
              >
                Schritt {i + 1}{" "}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(2),
                pl: { xs: theme.spacing(1.5), md: theme.spacing(4) },
                ml: { xs: "3px", md: theme.spacing(2) },
                py: { xs: theme.spacing(3), md: theme.spacing(5) },
                borderLeft: "1px solid #D1D1D1",
              }}
            >
              {duBrauchst && duBrauchst.length > 0 && (
                <Box>
                  <Typography
                    sx={{
                      mb: { xs: theme.spacing(0.5), md: theme.spacing(1) },
                      color: theme.palette.softBlack.dark,
                      fontWeight: 700,
                    }}
                    variant="body1"
                  >
                    Du brauchst
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: theme.spacing(1),
                    }}
                  >
                    {duBrauchst.map((db, i) => (
                      <Fragment key={`needs${i}`}>
                        <Typography sx={{ fontSize: "14px" }}>{db}</Typography>
                        {i < duBrauchst.length - 1 && (
                          <Typography sx={{ fontSize: "14px" }}>·</Typography>
                        )}
                      </Fragment>
                    ))}
                  </Box>
                </Box>
              )}
              {zutaten && (
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      mb: { xs: theme.spacing(0.5), md: theme.spacing(1) },
                      color: theme.palette.softBlack.dark,
                      fontWeight: 700,
                    }}
                  >
                    Zutaten
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: theme.spacing(1),
                    }}
                  >
                    {zutaten.map((zt, i) => (
                      <Fragment key={`ingredient${i}`}>
                        <Typography sx={{ fontSize: "14px" }}>{zt}</Typography>
                        {i < zutaten.length - 1 && (
                          <Typography sx={{ fontSize: "14px" }}> · </Typography>
                        )}
                      </Fragment>
                    ))}
                  </Box>
                </Box>
              )}
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.softBlack.dark,
                  // fontSize: { xs: "14px", md: "20px" },
                }}
              >
                {text}
              </Typography>
              {tipps && (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1),
                      mb: theme.spacing(1),
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        mb: { xs: theme.spacing(0.5), md: theme.spacing(1) },
                        color: theme.palette.softBlack.dark,
                        fontSize: { xs: "16px", md: "20px" },
                      }}
                    >
                      Tipp
                    </Typography>
                    <BulbIcon></BulbIcon>
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.softBlack.dark,
                      fontSize: { xs: "16px", md: "20px" },
                    }}
                  >
                    {tipps}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

function RecipeBadge(props: { color: string; icon: string; name: string }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { color, icon, name } = props;
  const Icon = icon;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "nowrap",
        backgroundColor: color,
        borderRadius: "100px",
        fontSize: {
          xs: "12px",
          sm: "14px",
        },
        p: theme.spacing(1, 2),
        gap: theme.spacing(1),
      }}
    >
      <Box
        sx={{
          width: isSmallScreen ? "12px" : "16px",
          height: isSmallScreen ? "12px" : "16px",
          " svg": {
            width: isSmallScreen ? "12px" : "16px",
            height: isSmallScreen ? "12px" : "16px",
            color: "white",
          },
        }}
      >
        <Icon />
      </Box>
      <Typography
        variant={isSmallScreen ? "caption" : "body1"}
        sx={{
          whiteSpace: "nowrap",
          textTransform: "uppercase",
          color: theme.palette.pureWhite.main,
        }}
      >
        {name}
      </Typography>
    </Box>
  );
}

function NutritionBox(props: { text: string; icon: string; color: string }) {
  const theme = useTheme();
  const { text, icon, color } = props;
  const Icon = icon;
  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: "5px",
        color: color,
        padding: "2px 6px",
        alignItems: "center",
        justifyContent: "center",
        whiteSpace: "nowrap",

        gap: { xs: 0.5, sm: 1 },
        " svg": {
          height: "16px",
        },
      }}
      style={{
        color: color,
      }}
    >
      <Icon></Icon>

      <Typography
        variant={"body1"}
        sx={{
          fontSize: { xs: "12px", sm: "16px" },
          color,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
function NutritionVerticalDivider(props: {}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "1px",
        height: "20px",
        backgroundColor: theme.palette.softBlack.main,
      }}
    ></Box>
  );
}

function decimalToCommonFraction(number: number) {
  const fractions = {
    "0.50": "½",
    "0.33": "⅓",
    "0.67": "⅔",
    "0.25": "¼",
    "0.75": "¾",
    "0.20": "⅕",
    "0.40": "⅖",
    "0.60": "⅗",
    "0.80": "⅘",
    "0.17": "⅙",
    "0.83": "⅚",
  } as any;

  const intPart = Math.floor(number);
  const decimalPart = (number - intPart).toFixed(2); // Round to 2 decimal places

  if (fractions[decimalPart]) {
    return `${intPart !== 0 ? intPart + " " : ""}${fractions[decimalPart]} `;
  }

  return Math.floor(number * 10) / 10; // Return original number if no common fraction is found
}
