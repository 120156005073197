import React from "react";

import { IImageCarouselFields } from "../../../@types/generated/contentful";
import ImageCarousel from "../../image-carousel";

const ImageCarouselSection = ({
  format,
  imageList,
  autoTransition,
  isExpandable,
  internal,
}: IImageCarouselFields) => {
  return (
    <ImageCarousel
      imageList={imageList}
      autoTransition={autoTransition}
      style="mini"
      format={format}
      isExpandable={isExpandable}
    />
  );
};

export default ImageCarouselSection;
