"use client";

import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Asset } from "contentful";
import { useSwipeable } from "react-swipeable";

import Image from "next/image";

import { MouseEventHandler, useEffect, useRef, useState } from "react";

import { withHttps } from "../../utils/helperFunctions";
import ImageFullScreenPanel from "../full-screen-image-dialog";
import { Arrow } from "./navigation/arrow";
import { PreviewImageRow } from "./navigation/image-preview-row";
import { ImageSelectionMinimap } from "./navigation/minimap";

export type ImageCarouselFormat = "1:1" | "16:9";
export type ImageCarouselStyle = "mini" | "default";
/**
 * Component renders an image-carousel
 * style can be "mini" or "default".
 * "default" renders an navigatable row of small image previews.
 * "mini" renders small clickable dots to indicate the currently viewed image.
 * @returns JSX.Element
 */
const ImageCarousel = ({
  imageList,
  format = "1:1",
  style = "default",
  autoTransition = false,
  isExpandable = false,
}: {
  imageList: Asset[];
  format?: ImageCarouselFormat;
  style?: ImageCarouselStyle;
  autoTransition?: Boolean;
  isExpandable?: Boolean;
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [viewerOpen, setViewerOpen] = useState(false);
  const [containerHeight, setContainerHeight] = useState(500);
  const sliderRef = useRef(null);
  const interval = useRef<NodeJS.Timer | null>(null);
  const imageContainer = useRef<HTMLDivElement>(null);

  const slideInterval = 4000;

  useEffect(() => {
    if (autoTransition) {
      startInterval();
      return () => clearInterval(interval.current!);
    }
  }, []);

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        setContainerHeight(entry.contentRect.height);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (imageContainer.current) {
      resizeObserver.observe(imageContainer.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    setCurrentIndex(0);
  }, [imageList]);

  useEffect(() => {
    if (imageContainer && imageContainer.current) {
      setContainerHeight(imageContainer.current.getBoundingClientRect().height);
    }
  }, [imageContainer]);

  const slideOrResetInterval = () => {
    const isSliderVisible = sliderRef
      ? isSliderInViewport(sliderRef.current)
      : false;
    if (isSliderVisible) {
      nextSlide();
    }
  };

  const startInterval = () => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
    interval.current = setInterval(slideOrResetInterval, slideInterval);
  };

  const handleUserInteraction = () => {
    if (autoTransition) {
      clearInterval(interval.current!);
      startInterval();
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => previousSlide(),
  });

  const previousSlide = () => {
    setCurrentIndex((prev) => (prev - 1 + imageList.length) % imageList.length);
  };

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % imageList.length);
  };

  const onImageClick = () => (isExpandable ? setViewerOpen(true) : nextSlide());

  const isSquareFormat = format === "1:1";
  const isMini = style === "mini";

  return (
    <Box
      ref={sliderRef}
      sx={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      {viewerOpen && (
        <ImageFullScreenPanel
          images={imageList}
          open={viewerOpen}
          onClose={() => setViewerOpen(false)}
          initialIndex={currentIndex}
        />
      )}
      <div
        {...swipeHandlers}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Box
          ref={imageContainer}
          sx={{
            position: "relative",
            width: "100%",
            overflow: "hidden",
            maxWidth:
              !isMini || isSquareFormat
                ? { xs: "310px", sm: "500px" }
                : (300 * 16) / 9 + "px",

            minHeight: !isSquareFormat ? "300px" : { xs: "310px", sm: "500px" },
            height: !isSquareFormat ? "300px" : "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: isMini ? "36px" : "0px",
          }}
        >
          <Arrow
            direction="left"
            clickFunction={() => {
              handleUserInteraction();
              previousSlide();
            }}
            glyph="&#9664;"
            isHovered={isHovered}
          />

          {imageList.map((image, i) => (
            <ImageSlide
              key={"imageSlide" + i}
              isExpandable={isExpandable}
              image={image}
              currentIndex={currentIndex}
              onClick={onImageClick}
              index={i}
              objectFit={style === "mini" ? "cover" : "contain"}
            />
          ))}
          <Arrow
            direction="right"
            clickFunction={() => {
              handleUserInteraction();
              nextSlide();
            }}
            glyph="&#9654;"
            isHovered={isHovered}
          />
        </Box>
        {style == "mini" && (
          <ImageSelectionMinimap
            imageAmount={imageList.length}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            height={containerHeight}
          />
        )}
      </div>
      {style == "default" && (
        <PreviewImageRow
          imageList={imageList}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
          imageAmount={imageList.length}
        />
      )}
    </Box>
  );
};

const ImageSlide = ({
  image,
  index,
  currentIndex,
  objectFit = "contain",
  isExpandable,
  onClick,
}: {
  image: Asset;
  index: number;
  currentIndex: number;
  objectFit?: "cover" | "contain";
  isExpandable: Boolean;
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
}) => {
  const theme = useTheme();
  const isLandscape =
    image.fields.file.details.image?.width! <
    image.fields.file.details.image?.height!;
  const isSelected = index == currentIndex;
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        transform: `translateX(${(index - currentIndex) * 100}%)`,
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme.components?.Image.borderRadius,
        overflow: "hidden",
        transition: "transform .5s ease-out",
        userSelect: "none",
        cursor: isExpandable ? "zoom-in" : "pointer",
      }}
      onClick={onClick}
    >
      <Image
        className="image-slide"
        sizes={`(max-width: 600px) 100vw, (max-width: 1200px) 42vw, 500px`}
        priority={index === 0}
        style={{
          opacity: isSelected ? 1 : 0,
          zIndex: 2,
          transition: "opacity .5s ease-out",
          objectFit: objectFit,
          borderRadius: theme.components?.Image.borderRadius,
          maxWidth: "100%",
          maxHeight: "100%",
          height:
            objectFit == "cover" ? "100%" : isLandscape ? "100%" : "unset",
          width: objectFit == "cover" ? "100%" : isLandscape ? "unset" : "100%",
        }}
        src={withHttps(image.fields.file.url)}
        width={image.fields.file.details.image?.width}
        height={image.fields.file.details.image?.height}
        alt={""}
      />
    </div>
  );
};

export default ImageCarousel;

const isSliderInViewport = (element: HTMLElement | null) => {
  if (!element) return false;

  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
