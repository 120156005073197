import { styled, useTheme } from "@mui/material/styles";
import { EmblaCarouselType } from "embla-carousel";

import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";

type UseDotButtonType = {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
};

const StyledDotButton = styled("button")<{ selected: boolean }>(
  ({ theme, selected }) => {
    return {
      transition:
        "width 0.2s cubic-bezier(.85,1.76,.72,.79)," +
        "background-color 0.2s cubic-bezier(.85,1.76,.72,.79)",
      height: "15px",
      borderRadius: "20px",
      borderWidth: 0,
      paddingBlock: 0,
      paddingInline: 0,
      border: "2px solid " + theme.palette.softBlack.main,
      borderColor: theme.palette.softBlack.main,
      textAlign: "center",
      backgroundColor: theme.palette.softBlack.main,
      boxSizing: "border-box",
      "&:hover": {
        backgroundColor: !selected
          ? "transparent!important"
          : theme.palette.softBlack.main,
        border: "2px solid " + theme.palette.softBlack.main,
      },
    };
  }
);

export const useDotButton = (
  emblaApi: EmblaCarouselType | undefined
): UseDotButtonType => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  };
};

type PropType = PropsWithChildren<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>;

interface DotButtonProps extends PropType {
  selected: boolean;
}
export const DotButton: React.FC<DotButtonProps> = (props) => {
  const { children, selected, ...restProps } = props;
  const theme = useTheme();
  return (
    <StyledDotButton
      selected={selected}
      className="embla_dot"
      style={{
        width: selected ? "35px" : "15px",
        cursor: selected ? "auto" : "pointer",
      }}
      {...restProps}
    />
  );
};
