"use client";

import { Box, Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";

import { useContext } from "react";

import {
  IContentSectionFields,
  IListFields,
  IQuoteSection,
  IQuoteSectionFields,
  ITrustpilotSectionFields,
} from "../../@types/generated/contentful";
import { ContentSectionThemeContext } from "../../utils/contexts";
import IdAnchor from "../id-anchor";
import ListSection from "../list-section";
import ContentSectionElementRenderer from "./helper/contentSectionElementRenderer";
import RichTextRenderer from "./helper/richTextRenderer";
import SectionQuote from "./section-quote";
import TrustpilotContentSection from "./section-trustpilot";

type ContentSectionProps = Pick<
  IContentSectionFields,
  | "sectionItems"
  | "heading"
  | "level"
  | "introText"
  | "fullWidth"
  | "internal"
  | "layout"
  | "isReversed"
>;

interface ContentSectionPropsExtended extends ContentSectionProps {
  lowMargin?: boolean;
}

/**
 * Represents the grid of a content section. Each section item will be rendered into a grid column
 *
 * @param sectionItems an array of content type elements (IText | IImage | IList | IMainHeading | ICtaButton | IAccordion | IVideo | ICardTeaser
 * @returns antd row element
 */
const ContentSection = ({
  layout = "1",
  isReversed,
  heading,
  fullWidth = false,
  level,
  sectionItems,
  introText,
  internal,
  lowMargin,
}: ContentSectionPropsExtended) => {
  const theme = useTheme();
  const sectionTheme = useContext(ContentSectionThemeContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  sectionItems = sectionItems || [];

  const colSpan = 12;
  let colSpanMd: number;
  let offsetMd = 0;

  const Variants = {
    H1: "h1",
    H2: "h2",
    H3: "h3",
  } as const;

  let gridSpacing = 0;

  if (sectionItems.length === 2) {
    gridSpacing = 5;
  } else {
    gridSpacing = 3;
  }

  let rowSpacing = lowMargin ? 0 : 3;

  let colSpansMd = [] as number[];
  if (layout == "1") {
    colSpansMd = fullWidth ? [12] : [8];
    offsetMd = fullWidth ? 0 : 2;
  } else if (layout == "2") {
    colSpansMd = [6, 6];
  } else if (layout == "3") {
    colSpansMd = [4, 4, 4];
  } else if (layout == "2:1") {
    colSpansMd = [8, 4];
  } else if (layout == "1:2") {
    colSpansMd = [4, 8];
  }

  const internalId = (internal || "").replace(/\s/g, "");

  const orderedItems = [...sectionItems];
  if (isSmallScreen && isReversed) {
    orderedItems.reverse();
  }

  return (
    <div style={{ position: "relative" }}>
      {" "}
      <IdAnchor id={internalId} />
      {(heading || introText) && (
        <Grid
          container
          sx={{
            mx: "auto",
            marginBottom: sectionItems.length ? theme.spacing(4) : 0,
            [theme.breakpoints.up("sm")]: {
              justifyContent: "center",
              textAlign: "center",
              maxWidth: "66.6%",
            },
          }}
        >
          {heading && (
            <Typography
              sx={{ width: "100%" }}
              color={sectionTheme.textColor}
              variant={(level && Variants[level]) || "h2"}
              component={(level && Variants[level]) || "h2"}
            >
              {heading}
            </Typography>
          )}
          {introText && (
            <Box sx={{ textAlign: "left", mt: 3 }}>
              <RichTextRenderer richTextDocument={introText} />
            </Box>
          )}
        </Grid>
      )}
      {!!sectionItems.length && (
        <Grid
          container
          columnSpacing={{ xs: 0, md: gridSpacing }}
          rowSpacing={{ xs: gridSpacing, md: rowSpacing }}
          sx={{
            ...(offsetMd && { justifyContent: "center" }),
          }}
        >
          {!!sectionItems.length &&
            orderedItems.map((sectionItem, index) => {
              switch (sectionItem.sys.contentType?.sys.id) {
                // special case for content of type IList. Inner grid config will be managed by <ListSection/> component
                case "list":
                  return (
                    <Grid
                      key={`grid-${sectionItem.sys.id}-${index}`}
                      item
                      xs={colSpan}
                    >
                      <ListSection
                        layout={layout}
                        {...(sectionItem.fields as IListFields)}
                      />
                    </Grid>
                  );
                case "quoteSection":
                  // special case for quote section to enable content section to be full width
                  return (
                    <Grid
                      item
                      xs={colSpan}
                      md={layout === "1" ? colSpan - 2 : colSpanMd}
                      key={`grid-${sectionItem.sys.id}-${index}`}
                    >
                      <SectionQuote
                        {...{
                          layout,
                          itemAmount: sectionItems ? sectionItems.length : 0,
                          ...(sectionItem.fields as IQuoteSectionFields),
                        }}
                      />
                    </Grid>
                  );
                case "trustpilotSection":
                  //The Trustpilot widget needs more width than the other content sections.
                  return (
                    <Grid
                      item
                      xs={colSpan}
                      md={layout === "1" ? colSpan - 2 : colSpanMd}
                      key={`grid-${sectionItem.sys.id}-${index}`}
                    >
                      <TrustpilotContentSection
                        {...(sectionItem.fields as ITrustpilotSectionFields)}
                      />
                    </Grid>
                  );
                default:
                  return (
                    <Grid
                      item
                      xs={colSpan}
                      md={colSpansMd[index % colSpansMd.length]}
                      key={`grid-${sectionItem.sys.id}-${index}`}
                    >
                      <ContentSectionElementRenderer
                        colspan={
                          layout === "1"
                            ? 12
                            : colSpansMd[index % colSpansMd.length]
                        }
                        element={sectionItem}
                      />
                    </Grid>
                  );
              }
            })}
        </Grid>
      )}
    </div>
  );
};

export default ContentSection;
