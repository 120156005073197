import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  List,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { useTheme } from "@mui/material/styles";

import Link from "next/link";

import { useContext } from "react";

import {
  IContentSectionFields,
  IListFields,
  IListItem,
} from "../../@types/generated/contentful";
import useContentSectionThemeContext from "../../hooks/useContentSectionThemeContext";
import useLink from "../../hooks/useLink";
import { ContentSectionThemeContext } from "../../utils/contexts";

interface IListSection extends IListFields {
  layout: IContentSectionFields["layout"];
}
interface IListElement {
  item: IListItem;
  sectionTextColor: string | undefined;
}

/**
 * Renders a list item with icon, headline and text wrapped in card component
 * @param {IListElement} item
 * @returns JSX.Element
 */
export const ListElement = ({ item, sectionTextColor }: IListElement) => {
  const theme = useTheme();
  const { getLinkProps } = useLink();
  // Define Avatar size
  const getImageSize = (imageDisplaySize: string) =>
    imageDisplaySize === "groß" ? 128 : 48;

  const {
    backgroundColor,
    headline,
    imageDisplaySize,
    image,
    text,
    link,
    level,
  } = item.fields;

  const titleLevel = level || "subtitle1";
  const itemTextColor =
    useContentSectionThemeContext(backgroundColor).textColor;
  const isBackgroundSet = backgroundColor && backgroundColor !== "none";
  const textColor = isBackgroundSet ? itemTextColor : sectionTextColor;
  const linkProps = link && getLinkProps(link?.fields);

  const content = (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "100%",
        backgroundColor:
          backgroundColor === "none" ? "transparent" : backgroundColor,
        boxShadow: "none",
        [theme.breakpoints.between("sm", "md")]: {
          maxWidth: "66.6%",
        },
        ...(!isBackgroundSet ? { borderRadius: 0 } : {}),
        p: isBackgroundSet ? theme.spacing(2) : 0,
        ...(linkProps ? { borderRadius: 0 } : {}),
      }}
    >
      <CardMedia>
        <Avatar
          src={image?.fields.file.url}
          className="list-avatar"
          sx={{
            width: `${getImageSize(imageDisplaySize)}px`,
            height: `${getImageSize(imageDisplaySize)}px`,
            borderRadius: 0,
            mx: "auto",
          }}
        />
      </CardMedia>
      <CardContent
        sx={{
          display: "flex",
          padding: 0,
          paddingTop: 3,
          alignItems: "flex-start",
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        <ListItem
          sx={{
            display: "flex",
            flexDirection: { xs: "column" },
            alignItems: "center",
            paddingLeft: theme.spacing(2),
            textAlign: "center",
            py: 0,
          }}
        >
          {headline && (
            <Typography
              variant={titleLevel}
              component="h3"
              sx={{
                color: textColor,
                mb: 1,
                wordBreak: "break-word",
                textAlign: "center",
              }}
            >
              {headline}
            </Typography>
          )}
          <Typography
            variant="body1"
            component="span"
            sx={{ color: textColor }}
          >
            {text}
          </Typography>
        </ListItem>
      </CardContent>
    </Card>
  );
  return linkProps ? (
    <Link
      href={linkProps.href}
      target={linkProps.linkTarget}
      style={{
        width: "100%",
        textDecoration: "none",
      }}
    >
      {content}
    </Link>
  ) : (
    content
  );
};

/**
 * Renders a list item with text & icon as bullet point.
 * @param {IListElement} item
 * @returns JSX.Element
 */
export const ListRowElement = ({ item, sectionTextColor }: IListElement) => {
  const { getLinkProps } = useLink();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { backgroundColor, headline, imageDisplaySize, image, text, link } =
    item.fields;

  const itemTextColor =
    useContentSectionThemeContext(backgroundColor).textColor;
  const isBackgroundSet = backgroundColor && backgroundColor !== "none";
  const textColor = isBackgroundSet ? itemTextColor : sectionTextColor;
  const isPortrait =
    image &&
    image?.fields.file.details.image &&
    image?.fields.file.details.image?.width <
      image?.fields.file.details.image?.height;

  const linkProps = link && getLinkProps(link?.fields);

  return link ? (
    <Link
      href={linkProps?.href!}
      target={linkProps?.linkTarget}
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        width:
          imageDisplaySize == "klein"
            ? isSmallScreen
              ? "90vw"
              : "400px"
            : "100%",

        textDecoration: "none",
      }}
    >
      {imageDisplaySize == "klein" ? (
        <SmallRowElement item={item} sectionTextColor={textColor} />
      ) : (
        <LargeRowElement item={item} sectionTextColor={textColor} />
      )}
    </Link>
  ) : imageDisplaySize == "klein" ? (
    <SmallRowElement item={item} sectionTextColor={textColor} />
  ) : (
    <LargeRowElement item={item} sectionTextColor={textColor} />
  );
};

/**
 * Component renders an ant list with dynamic width (outer grid) and dynamic number of columns
 *
 * @param layout column layout
 * @param IListFields IList fields (listItems)
 * @returns
 */
const ListSection = ({
  layout,
  headline,
  listItems,
  isRows,
  alignment,
}: IListSection) => {
  const colSpan = 12;
  const theme = useTheme();

  const sectionTextColor = useContext(ContentSectionThemeContext).textColor;
  const textColor = sectionTextColor;

  let colSpans = [] as number[];
  // Define column span for list grid
  switch (layout) {
    // xs-sm: Width: 100%, 1 Column
    // md-xxl: Width 66%, 1 Column with offset
    case "1":
      colSpans = [8];
      break;
    case "2":
      colSpans = [6, 6];
      break;
    case "3":
      colSpans = [4, 4, 4];
      break;
    case "1:2":
      colSpans = [4, 8];
      break;
    case "2:1":
      colSpans = [8, 4];

      break;
    default:
      // eslint-disable-next-line no-console
      console.warn(`Column count: ${layout} is not  allowed`);
      return null;
  }
  return (
    <Grid
      item
      xs={colSpan}
      md={colSpan}
      sx={{
        my: {
          md: isRows ? theme.spacing(1) : `-${theme.spacing(4)}`,
        },
      }}
    >
      {headline && (
        <Typography
          variant="h2"
          sx={{
            color: sectionTextColor,
            pb: 2,
            textAlign: {
              sm: "center",
            },
          }}
        >
          {headline}
        </Typography>
      )}
      <List sx={{ py: 0 }}>
        <Grid
          className="list-section"
          container
          direction="row"
          rowSpacing={isRows ? 1 : { xs: 3, md: 0 }}
          columnSpacing={{ xs: 0, md: 3 }}
        >
          {listItems.map((item, i) => {
            return (
              <Grid
                className="list-section-item"
                item
                md={colSpans[i % colSpans.length]}
                sx={{
                  display: "flex",
                  boxSizing: "border-box",
                  width: "100%",
                  flexDirection: {
                    xs: `${"column"}`,
                    lg: "row",
                  },
                  justifyContent:
                    alignment === "zentriert" ? "center" : "flex-start",
                  alignItems: "center",
                }}
                key={item.sys.id + i}
              >
                {isRows ? (
                  <ListRowElement item={item} sectionTextColor={textColor} />
                ) : (
                  <ListElement item={item} sectionTextColor={textColor} />
                )}
              </Grid>
            );
          })}
        </Grid>
      </List>
    </Grid>
  );
};

export default ListSection;

const SmallRowElement = ({ item, sectionTextColor }: IListElement) => {
  const { image, headline, text, backgroundColor, link, level } = item.fields;

  const titleLevel = level || "subtitle1";
  const theme = useTheme();
  const itemTextColor =
    useContentSectionThemeContext(backgroundColor).textColor;
  const isBackgroundSet = backgroundColor && backgroundColor !== "none";
  const textColor = isBackgroundSet ? itemTextColor : sectionTextColor;
  const isPortrait =
    image &&
    image?.fields.file.details.image &&
    image?.fields.file.details.image?.width <
      image?.fields.file.details.image?.height;

  const imageSize = 24;

  return (
    <Card
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "row",
        justifyContent: "flex-start",
        height: "100%",
        backgroundColor,
        boxShadow: "none",
        borderRadius: "10px",
        maxWidth: "400px",
        boxSizing: "border-box",
        [theme.breakpoints.between("sm", "md")]: {
          maxWidth: "66.6%",
        },
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100vw",
          width: "90vw",
        },
        p: isBackgroundSet ? theme.spacing(1) : 0,
        alignItems: "center",

        ...(link
          ? {
              "&:hover .overlay": {
                transform: "scale(1.02,1)",
                filter: "invert(15%)",
                backgroundColor,
              },
              "&:active .overlay": {
                transform: "scale(1,1)",
                filter: "invert(20%)",
                backgroundColor,
              },
            }
          : {}),
      }}
    >
      <Box
        className="overlay"
        sx={{
          transform: "scale(1,1)",
          filter: "invert(0%)",
          transition:
            "0.25s filter ease-in-out,0.25s transform ease-in-out,background-color 0.25s ease-out",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor,
        }}
      />
      <CardMedia
        sx={{
          objectFit: "contain",
          ml: theme.spacing(1),
        }}
      >
        <Avatar
          src={image?.fields.file.url}
          className="list-avatar"
          sx={{
            borderRadius: 0,
            width: isPortrait ? "auto" : `${imageSize}px`,
            height: !isPortrait ? "auto" : `${imageSize}px`,
            objectFit: "contain",
            mx: "auto",
          }}
        />
      </CardMedia>
      <CardContent
        sx={{
          display: "flex",
          padding: 0,
          paddingTop: 0,
          alignItems: "center",
          justifyContent: "flex-start",
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        <ListItem
          sx={{
            display: "flex",
            flexDirection: { xs: "column" },
            alignItems: "flex-start",
            paddingLeft: theme.spacing(2),
            textAlign: "left",
            py: 0,
          }}
        >
          {headline && (
            <Typography
              variant={titleLevel}
              component="h3"
              sx={{
                color: textColor,
                mb: 1,
                wordBreak: "break-word",
                textAlign: "center",
              }}
            >
              {headline}
            </Typography>
          )}
          <Typography
            variant="body1"
            component="span"
            sx={{ color: textColor }}
          >
            {text}
          </Typography>
        </ListItem>
      </CardContent>
    </Card>
  );
};
const LargeRowElement = ({ item, sectionTextColor }: IListElement) => {
  const { image, headline, text, backgroundColor, link, level } = item.fields;
  const titleLevel = level || "subtitle1";
  const theme = useTheme();
  const itemTextColor =
    useContentSectionThemeContext(backgroundColor).textColor;
  const isBackgroundSet = backgroundColor && backgroundColor !== "none";
  const textColor = isBackgroundSet ? itemTextColor : sectionTextColor;
  const isPortrait =
    image &&
    image?.fields.file.details.image &&
    image?.fields.file.details.image?.width <
      image?.fields.file.details.image?.height;

  const imageSize = 128;

  return (
    <Card
      sx={{
        display: "flex",
        maxWidth: "1200px",
        width: "100%",
        alignItems: "stretch",
        justifyContent: "flex-start",
        p: theme.spacing(2),
        backgroundColor:
          backgroundColor === "none" ? "transparent" : backgroundColor,
        ...(link
          ? {
              filter: "invert(0%)",
              transition: "0.25s filter ease-in-out",

              "&:hover": {
                filter: "invert(15%)",
              },
            }
          : {}),
      }}
    >
      <CardMedia
        sx={{
          display: "flex",
          alignSelf: "center",
          objectFit: "contain",
          mr: theme.spacing(2),
        }}
      >
        <Avatar
          src={image?.fields.file.url}
          className="list-avatar"
          sx={{
            width: isPortrait ? "auto" : `${imageSize}px`,
            height: !isPortrait ? "auto" : `${imageSize}px`,
            objectFit: "contain",
            borderRadius: 0,
            mx: "auto",
            mr: theme.spacing(2),
          }}
        />
      </CardMedia>
      <CardContent
        sx={{
          display: "flex",
          padding: 0,
          paddingTop: 0,
          flexDirection: "column",

          alignItems: "flex-start",
          justifyContent: "flex-start",
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        {headline && (
          <Typography
            variant={titleLevel}
            component="h3"
            sx={{
              color: textColor,
              mb: 1,
              wordBreak: "break-word",
              textAlign: "center",
            }}
          >
            {headline}
          </Typography>
        )}
        <Typography variant="body1" component="span" sx={{ color: textColor }}>
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
};
