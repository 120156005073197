import { Add, Remove } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import MinusIcon from "../../../components/icons/recipes/Minus.svg";
import PlusIcon from "../../../components/icons/recipes/Plus.svg";

export default function PortionToggle(props: {
  amount: number;
  label: string;
  labelPlural: string;
  setAmount: (amount: number) => void;
}) {
  const theme = useTheme();
  const {
    setAmount,
    amount,
    label = "Portion",
    labelPlural = "Portionen",
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: { sm: theme.spacing(5), md: theme.spacing(10) },
        borderRadius: "5px",
        border: "1.5px solid #4A4A4A",
        padding: theme.spacing(1.5, 2.5),
        boxSizing: "border-box",
        width: { xs: "100%", sm: "fit-content" },
        height: "70px",
      }}
    >
      <IconButton
        onClick={() => {
          setAmount(Math.max(1, amount - 1));
        }}
        size="large"
        color="inherit"
        sx={{ padding: 0 }}
      >
        <MinusIcon sx={{ fontSize: { sm: "36px", md: "44px" } }} />
      </IconButton>
      <Typography variant="body1" sx={{ fontSize: { sm: "16px", md: "20px" } }}>
        {amount} {amount == 1 ? label : labelPlural}
      </Typography>
      <IconButton
        sx={{ padding: 0 }}
        size="large"
        onClick={() => setAmount(amount + 1)}
        color="inherit"
      >
        <PlusIcon sx={{ fontSize: { sm: "36px", md: "44px" } }} />
      </IconButton>
    </Box>
  );
}
