import { ArrowBack, ArrowForward, Close } from "@mui/icons-material";
import { Box, CircularProgress, Dialog, Fade, IconButton } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import { Asset } from "contentful";
import { useSwipeable } from "react-swipeable";

import Image from "next/image";

import { useEffect, useRef, useState } from "react";
import React from "react";

import useContentSectionThemeContext from "../../hooks/useContentSectionThemeContext";
import { withHttps } from "../../utils/helperFunctions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} {...props} timeout={600} />;
});

const ImageFullScreenPanel = (props: {
  images: Asset[];
  initialIndex: number;
  open: any;
  onClose: any;
}) => {
  const { images, initialIndex, open, onClose } = props;
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const theme = useTheme();
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handlePrev(),
    onSwipedRight: () => handleNext(),
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowLeft":
          handlePrev();
          break;
        case "ArrowRight":
          handleNext();
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex, images.length]);

  const handlePrev = () => {
    setCurrentIndex(
      (prev: number) => (prev - 1 + images.length) % images.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % images.length);
  };

  const handleOnClick = (ev: any) => {
    if (
      ev.target &&
      !(ev.target as Element).classList!.contains("fullscreenImage") &&
      !(ev.target as Element).classList!.contains("arrow") &&
      (ev.target as Element).tagName !== "path"
    ) {
      onClose();
    } else if (
      !(ev.target as Element).classList!.contains("arrow") &&
      (ev.target as Element).tagName !== "path"
    ) {
      handleNext();
    }
  };
  const textColor = useContentSectionThemeContext(
    theme.palette.secondary.main
  ).textColor;
  const StyledIconButton = styled(IconButton)`
    background-color: ${theme.palette.secondary.main};
    color: ${textColor};
    position: absolute;
    z-index: 12;
  `;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      fullScreen
      hideBackdrop={true}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.pureWhite.main,
          boxShadow: "none",
        },
      }}
      {...swipeHandlers}
      onClick={handleOnClick}
    >
      <StyledIconButton
        sx={{
          right: theme.spacing(2),
          top: theme.spacing(2),
          "&:hover": {
            backgroundColor: theme.palette.secondary.light,
          },
        }}
        onClick={onClose}
      >
        <Close />
      </StyledIconButton>

      <FullScreenImage image={images[currentIndex]}></FullScreenImage>
      <StyledIconButton
        className="arrow"
        sx={{
          left: theme.spacing(4),
          top: "50%",
          transform: "translateY(-50%)",
          "&:hover": {
            backgroundColor: theme.palette.secondary.light,
          },
        }}
        onClick={(ev) => {
          ev.preventDefault();
          handlePrev();
        }}
      >
        <ArrowBack className="arrow" />
      </StyledIconButton>

      <StyledIconButton
        className="arrow"
        sx={{
          right: theme.spacing(4),
          top: "50%",
          transform: "translateY(-50%)",
          "&:hover": {
            backgroundColor: theme.palette.secondary.light,
          },
        }}
        onClick={(ev) => {
          ev.preventDefault();
          handleNext();
        }}
      >
        <ArrowForward className="arrow" />
      </StyledIconButton>
    </Dialog>
  );
};
export default ImageFullScreenPanel;

function isImageLoaded(ref: any) {
  console.log(
    ref,
    ref ? ref.current : 0,
    ref && ref.current ? ref.current.complete : 0
  );
  return ref && ref.current && ref.current.complete;
}

const FullScreenImage = (props: { image: Asset }) => {
  const { image } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imgRef && imgRef.current && imgRef.current.complete) {
      setIsLoaded(true);
    }
  }, [imgRef, imgRef.current]);

  return (
    <>
      {isLoaded ? null : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        sx={{
          width: image.fields.file.details.image?.width + "px",
          height: image.fields.file.details.image?.height + "px",
          maxWidth: "90vw",
          maxHeight: !isLoaded ? "0" : "90vh",
          position: "relative",
          margin: "auto",
        }}
      >
        <Image
          ref={imgRef}
          className="fullscreenImage"
          loading="eager"
          sizes="90vw"
          src={withHttps(image.fields.file.url)}
          alt=""
          fill
          onLoad={() => setIsLoaded(true)}
          onLoadingComplete={() => setIsLoaded(true)}
          style={{
            cursor: "pointer",
            opacity: isLoaded ? "1" : "0",
            objectFit: "contain",
            display: isLoaded ? "block" : "none",
            margin: "auto",
          }}
        />
      </Box>
    </>
  );
};
