import {
  IAccordion,
  IAccordionFields,
  IAudioSection,
  IButtonList,
  IButtonListFields,
  ICardTeaser,
  ICardTeaserFields,
  ICtaButton,
  ICtaButtonFields,
  IHubspotForm,
  IHubspotFormFields,
  IImage,
  IImageCarousel,
  IImageCarouselFields,
  IImageFields,
  IList,
  IListCard,
  IListCardFields,
  IMainHeading,
  IMainHeadingFields,
  IProductTile,
  IProductTileFields,
  IQuoteSection,
  IQuoteSectionFields,
  IRecipeSection,
  IRecipeSectionFields,
  IText,
  ITextFields,
  ITrustpilotSection,
  ITrustpilotSectionFields,
  IVideo,
  IVideoFields,
} from "../../../@types/generated/contentful";
import ButtonList from "../button-list-section";
import CardListElement from "../card-list";
import ProductTile from "../product-tile";
import SectionAccordion from "../section-accordion";
import SectionButton from "../section-button";
import SectionCardTeaser from "../section-card";
import SectionHubspotForm from "../section-hubspot-form";
import SectionImage from "../section-image";
import ImageCarouselSection from "../section-image-carousel";
import SectionMainHeading from "../section-main-heading";
import SectionText from "../section-text";
import TrustpilotContentSection from "../section-trustpilot";
import SectionVideo from "../section-video";

type Props = {
  colspan: number;
  element:
    | IText
    | IImage
    | IList
    | IMainHeading
    | ICtaButton
    | IAccordion
    | IVideo
    | ICardTeaser
    | ITrustpilotSection
    | IQuoteSection
    | IHubspotForm
    | IImageCarousel
    | IButtonList
    | IListCard
    | IProductTile
    | IAudioSection;
};

/**
 * Determines which component should be rendererd for given section element
 *
 * @param {IText | IImage | IList | IMainHeading | ICtaButton  | IAccordion | IVideo | ICardTeaser  | IHubspotForm  | ITrustpilotSection | IImageCarousel |IButtonList | IListCard | IProductTile } element - section content element
 * @returns component matching element type
 */
const ContentSectionElementRenderer = ({ element, colspan }: Props) => {
  const contentTypeId = element.sys.contentType?.sys.id;

  switch (contentTypeId) {
    case "image":
      return (
        <SectionImage {...(element.fields as IImageFields)} colspan={colspan} />
      );
    case "text":
      return <SectionText {...(element.fields as ITextFields)} />;
    case "mainHeading":
      return <SectionMainHeading {...(element.fields as IMainHeadingFields)} />;
    case "ctaButton":
      return <SectionButton {...(element.fields as ICtaButtonFields)} />;

    case "accordion":
      return <SectionAccordion {...(element.fields as IAccordionFields)} />;
    case "video":
      return <SectionVideo {...(element.fields as IVideoFields)} />;
    case "cardTeaser":
      return <SectionCardTeaser {...(element.fields as ICardTeaserFields)} />;
    case "trustpilotSection":
      return (
        <TrustpilotContentSection
          {...(element.fields as ITrustpilotSectionFields)}
        />
      );

    case "hubspotForm":
      return <SectionHubspotForm {...(element.fields as IHubspotFormFields)} />;
    case "imageCarousel":
      return (
        <ImageCarouselSection {...(element.fields as IImageCarouselFields)} />
      );
    case "buttonList":
      return <ButtonList {...(element.fields as IButtonListFields)} />;
    case "listCard":
      return <CardListElement {...(element.fields as IListCardFields)} />;
    case "productTile":
      return <ProductTile {...(element.fields as IProductTileFields)} />;
    default:
      // eslint-disable-next-line no-console
      console.warn(`${contentTypeId} is not yet implemented`);
      return null;
  }
};

export default ContentSectionElementRenderer;
