import React from "react";

import { IVideoFields } from "../../../@types/generated/contentful";
import Video from "../video";

/**
 * Wrapper for videocomponent to be displayed in content-section
 *
 * @param VideoData IVideoFields
 * @returns
 */
const SectionVideo: React.FC<IVideoFields> = ({
  altText,
  embedCode,
  internal,
}) => {
  return <Video altText={altText} embedCode={embedCode} internal={internal} />;
};

export default SectionVideo;
