import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useContext } from "react";

import {
  IAccordionElementFields,
  IAccordionFields,
} from "../../../@types/generated/contentful";
import { ContentSectionThemeContext } from "../../../utils/contexts";
import RichTextRenderer from "../helper/richTextRenderer";

export interface SectionAccordionElementProps extends IAccordionElementFields {
  firstElementExpanded?: boolean;
  level: string;
}

/**
 * An accordion element is composed of the summary (heading) and a details part.
 * The later can be opened/closed with a click on the former.
 *
 * @param {IAccordionElementFields} props
 * @returns JSX.Element
 */
const SectionAccordionElement = ({
  internal,
  heading,
  content,
  firstElementExpanded,
  level,
}: SectionAccordionElementProps) => {
  return (
    <Accordion disableGutters defaultExpanded={firstElementExpanded}>
      <AccordionSummary
        color={useContext(ContentSectionThemeContext).textColor}
        expandIcon={<ExpandMoreIcon color="secondary" fontSize="large" />}
      >
        <Typography
          color={useContext(ContentSectionThemeContext).textColor}
          id={internal}
          variant={level as any}
          component={level as any}
        >
          {heading}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <RichTextRenderer richTextDocument={content} />
      </AccordionDetails>
    </Accordion>
  );
};

/**
 * An accordion encompasses one or more accordion elements and can also display a heading.
 *
 * @param {IAccordionFields} props
 * @returns JSX.Element
 */
const SectionAccordion = ({
  internal,
  heading,
  accordionElements,
  firstExpanded,
  level = "h3",
}: IAccordionFields) => {
  return (
    <div className="section-accordion">
      <Typography
        color={useContext(ContentSectionThemeContext).textColor}
        id={internal}
        variant={level}
        marginBottom={useTheme().spacing(2)}
      >
        {heading}
      </Typography>
      {accordionElements.map((element, index) => {
        const { internal, heading, content } = element.fields;
        return (
          <SectionAccordionElement
            internal={internal}
            heading={heading}
            content={content}
            key={internal}
            level={level}
            firstElementExpanded={index === 0 && firstExpanded}
          />
        );
      })}
    </div>
  );
};
export default SectionAccordion;
