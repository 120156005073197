"use client";

import {
  Box,
  Checkbox,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Image from "next/image";
import { useRouter } from "next/router";

import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";

import { IRecipeSectionFields } from "../../../@types/generated/contentful";
import BulbIcon from "../../../components/icons/recipes/Bulb.svg";
import UnvertraeglichkeitenIcon from "../../../components/icons/recipes/Unvertraeglichkeiten.svg";
import EvergreenIcon from "../../../components/icons/recipes/badges/Evergreen.svg";
import FreezableIcon from "../../../components/icons/recipes/badges/Freeze.svg";
import GlutenIcon from "../../../components/icons/recipes/badges/Gluten.svg";
import MilchfreiIcon from "../../../components/icons/recipes/badges/Milchfrei.svg";
import SchlankboosterIcon from "../../../components/icons/recipes/badges/Schlankbooster.svg";
import VeganIcon from "../../../components/icons/recipes/badges/Vegan.svg";
import VegetarianIcon from "../../../components/icons/recipes/badges/Vegetarisch.svg";
import TauschIcon from "../../../components/icons/recipes/data.svg";
import { withHttps } from "../../../utils/helperFunctions";

const RecipeSectionPrint = ({
  internal,
  title,
  kcal,
  protein,
  fat,
  carbs,
  imageSquare,
  imageCredits,
  vegetarian,
  vegan,
  noGluten,
  noLactose,
  freezable,
  evergreen,
  schlankbooster,
  prepTime,
  cookTime,
  cookTimeLabel = "Kochzeit",
  portions,
  portionLabel = "Portion",
  portionPluralLabel = "Portionen",
  ingredients = [],
  contentSections,
  stepsTitle,
  steps = [],
  extraTips = [],
}: IRecipeSectionFields) => {
  const theme = useTheme();
  const [currentPortions, setCurrentPortions] = useState(portions || 2);
  const ingredientList = ingredients as Ingredient[];
  const [checkedIngredients, setCheckedIngredients] = useState(
    ingredientList.map((ing) => false)
  );
  const router = useRouter();

  /**necessary to reset state when switching between recipe pages */
  useEffect(() => {
    return () => {
      setCurrentPortions(portions || 2);
      setCheckedIngredients(ingredientList.map((ing) => false));
    };
  }, []);

  return (
    <Fragment key={router.asPath}>
      {/*Image + Title + Badges*/}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "64px",
        }}
      >
        <Grid
          container
          columnSpacing={6}
          rowSpacing={3}
          sx={{
            maxWidth: "670px",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "16px",
              }}
            >
              <Image
                src={
                  "https://images.ctfassets.net/62mq8hmhdldf/2qMXHDQuxQK6lXwifKvgDv/ad7af2c4f3ca1a78a52978cbd1b680f2/logo-docfleck.png"
                }
                width={73}
                height={24.43}
                alt="Logo"
              />

              <Box sx={{ display: "flex", gap: "16px" }}>
                {evergreen && (
                  <RecipeBadge
                    color={"#6BB8D4"}
                    icon={EvergreenIcon}
                    name="Evergreen"
                  />
                )}
                {schlankbooster && (
                  <RecipeBadge
                    color={"#FF9CA9"}
                    icon={SchlankboosterIcon}
                    name="Schlankbooster"
                  />
                )}
                {vegetarian && (
                  <RecipeBadge
                    color={"#B9DDCF"}
                    icon={VegetarianIcon}
                    name="Vegetarisch"
                  />
                )}
                {vegan && (
                  <RecipeBadge
                    color={"#97C4B0"}
                    icon={VeganIcon}
                    name="Vegan"
                  />
                )}
                {noGluten && (
                  <RecipeBadge
                    color={"#D6BDAC"}
                    icon={GlutenIcon}
                    name="Glutenfrei"
                  />
                )}
                {noLactose && (
                  <RecipeBadge
                    color={"#E9D6CC"}
                    icon={MilchfreiIcon}
                    name="Milchfrei"
                  />
                )}
                {freezable && (
                  <RecipeBadge
                    color={"#0CAAED"}
                    icon={FreezableIcon}
                    name="Zum Einfrieren"
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "15px",
                fontWeight: 900,
                pb: "14px",
              }}
              component={"h1"}
            >
              {title}
            </Typography>
            <RecipeSteps
              title={stepsTitle || ""}
              steps={steps as RecipeStep[]}
            ></RecipeSteps>
          </Grid>
          <Grid item xs={5}>
            {/* img */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  boxSizing: "border-box",
                }}
              >
                <Image
                  sizes={`181px`}
                  width={181}
                  height={104}
                  style={{
                    borderRadius: "15px",
                  }}
                  src={withHttps(imageSquare.fields.file.url)}
                  alt={""}
                />
              </Box>

              {/* nutr */}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{ fontSize: "8px", whiteSpace: "nowrap", mb: "6px" }}
                  variant="body1"
                >
                  Nährwerte pro {portionLabel}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  {kcal != undefined && (
                    <Typography
                      variant={"body1"}
                      sx={{
                        fontSize: "8px",
                      }}
                    >
                      {kcal + " kcal"}
                    </Typography>
                  )}
                  {(protein !== undefined ||
                    fat !== undefined ||
                    carbs !== undefined) && <NutritionVerticalDivider />}

                  {protein != undefined && (
                    <Typography
                      variant={"body1"}
                      sx={{
                        fontSize: "8px",
                      }}
                    >
                      {protein + " g Eiweiß"}
                    </Typography>
                  )}
                  {(fat !== undefined || carbs !== undefined) && (
                    <NutritionVerticalDivider />
                  )}
                  {fat != undefined && (
                    <Typography
                      variant={"body1"}
                      sx={{
                        fontSize: "8px",
                      }}
                    >
                      {fat + " g Fett"}
                    </Typography>
                  )}
                  {carbs !== undefined && <NutritionVerticalDivider />}
                  {carbs != undefined && (
                    <Typography
                      variant={"body1"}
                      sx={{
                        fontSize: "8px",
                      }}
                    >
                      {carbs + " g Kohlenhydrate"}
                    </Typography>
                  )}
                </Box>
              </Box>
              <HorizontalDivider />
              {/* time */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "flex-start",
                  gap: "10px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <TimeBox time={prepTime} text="Zubereitung" />
                </Box>

                <Box sx={{ display: "flex" }}>
                  <TimeBox time={cookTime} text={cookTimeLabel} />
                </Box>

                <Box sx={{ display: "flex" }}>
                  <TimeBox
                    time={(cookTime || 0) + (prepTime || 0)}
                    text="Gesamtzeit"
                  />
                </Box>
              </Box>
              <HorizontalDivider />
              {/* ingr */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontSize: "10px", fontWeight: 700 }}
                >
                  Zutaten für {currentPortions}{" "}
                  {currentPortions == 1 ? portionLabel : portionPluralLabel}
                </Typography>
                <IngredientList
                  ingredients={ingredients as Ingredient[]}
                  portions={currentPortions}
                  originalPortions={portions || 2}
                  checkedIngredients={checkedIngredients}
                  setCheckedIngredients={setCheckedIngredients}
                ></IngredientList>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mt: "16px" }}>
            <HorizontalDivider />
          </Grid>
          <Grid item xs={12}>
            <ExtraTips
              title={"Weitere Tipps für dich:"}
              extraTips={extraTips as ExtraTipp[]}
            ></ExtraTips>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default RecipeSectionPrint;

type ExtraTipp = {
  type: string;
  texts: string[];
};
function ExtraTips(props: { title: string; extraTips: ExtraTipp[] }) {
  const theme = useTheme();
  const { extraTips, title } = props;

  const iconMap = {
    "Evergreen-Extras": { icon: EvergreenIcon, color: "#6BB8D4" },
    "Tausch-Tipps": { icon: TauschIcon, color: "#B2CCBC" },
    Unverträglichkeiten: { icon: UnvertraeglichkeitenIcon, color: "#D6BDAC" },
  };
  const getIcon = (
    type: "Evergreen-Extras" | "Tausch-Tipps" | "Unverträglichkeiten"
  ) => {
    return iconMap[type] || <></>;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      {extraTips.map((tipp, i) => {
        const { type, texts } = tipp;
        const { icon: TippIcon, color } = getIcon(type as any);
        return (
          <Box
            key={`tipp${i}`}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Typography sx={{ fontSize: "10px", fontWeight: 700 }}>
                {type}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              {texts.map((text, i) => (
                <Typography
                  sx={{ fontSize: "10px" }}
                  key={"tippText" + type + i}
                >
                  {text}
                </Typography>
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

function TimeBox(props: { time: number; text: string }) {
  const { text, time } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",

        alignItems: "flex-start",
        gap: 0,
      }}
    >
      <Typography
        sx={{
          fontSize: "8px",
        }}
      >
        {text} {time} Min.
      </Typography>
    </Box>
  );
}
type Ingredient = {
  name: string;
  plural?: string;
  amount: number;
  unit?: string;
};
function IngredientList(props: {
  originalPortions: number;
  ingredients: Ingredient[];
  checkedIngredients: boolean[];
  setCheckedIngredients: Dispatch<SetStateAction<boolean[]>>;
  portions: number;
}) {
  const { ingredients, portions, originalPortions } = props;

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "flex-start",
        boxSizing: "border-box",
      }}
    >
      <List sx={{ py: 0, pl: "16px", listStyleType: "disc" }}>
        {ingredients.map((ingredient, i) => {
          const { name, amount, unit, plural } = ingredient;
          return (
            <ListItem
              key={"ingredient" + i}
              sx={{
                pt: 0,
                display: "list-item",
              }}
            >
              <Typography component={"span"} sx={{ fontSize: "10px" }}>
                {amount &&
                  decimalToCommonFraction(
                    (amount * portions) / originalPortions
                  )}{" "}
                {unit?.trimStart()}{" "}
                {(amount * portions) / originalPortions <= 1 || !plural
                  ? name
                  : plural}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

type RecipeStep = {
  text: string;
  tipps?: string;
  zutaten?: string[];
  duBrauchst?: string[];
};

function RecipeSteps(props: { title: string; steps: RecipeStep[] }) {
  const theme = useTheme();

  const { steps, title } = props;
  return (
    <>
      <Typography fontSize={"12px"}>{title}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {steps.map((step, i) => {
          const { duBrauchst, text, tipps, zutaten } = step;
          return (
            <Box key={`step${i}`}>
              <Typography
                sx={{ color: "black", fontSize: "20px", fontWeight: 700 }}
              >
                {i + 1}.
              </Typography>

              <Box
                sx={{
                  mt: "13px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "13px",
                }}
              >
                {duBrauchst && (
                  <Box>
                    <Typography
                      sx={{
                        color: theme.palette.softBlack.dark,
                        fontWeight: 700,
                        fontSize: "10px",
                        mb: "6px",
                      }}
                      variant="body1"
                    >
                      Du brauchst
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "4px",
                      }}
                    >
                      {duBrauchst.map((db, i) => (
                        <Fragment key={`needs${i}`}>
                          <Typography sx={{ fontSize: "8px" }}>{db}</Typography>
                          {i < duBrauchst.length - 1 && (
                            <Typography sx={{ fontSize: "10px" }}>·</Typography>
                          )}
                        </Fragment>
                      ))}
                    </Box>
                  </Box>
                )}
                {zutaten && (
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme.palette.softBlack.dark,
                        fontWeight: 700,
                        fontSize: "10px",
                        mb: "6px",
                      }}
                    >
                      Zutaten
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "4px",
                      }}
                    >
                      {zutaten.map((zt, i) => (
                        <Fragment key={`ingredient${i}`}>
                          <Typography sx={{ fontSize: "8px" }}>{zt}</Typography>
                          {i < zutaten.length - 1 && (
                            <Typography sx={{ fontSize: "10px" }}>
                              {" "}
                              ·{" "}
                            </Typography>
                          )}
                        </Fragment>
                      ))}
                    </Box>
                  </Box>
                )}
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "10px",
                  }}
                >
                  {text}
                </Typography>

                {tipps && (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1),
                        mb: theme.spacing(1),
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          mb: { xs: theme.spacing(0.5), md: theme.spacing(1) },
                          color: theme.palette.softBlack.dark,
                          fontSize: { xs: "16px", md: "20px" },
                        }}
                      >
                        Tipp
                      </Typography>
                      <BulbIcon></BulbIcon>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme.palette.softBlack.dark,
                        fontSize: { xs: "16px", md: "20px" },
                      }}
                    >
                      {tipps}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}

function RecipeBadge(props: { color: string; icon: string; name: string }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { color, icon, name } = props;
  const Icon = icon;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "6px",
        flexWrap: "nowrap",
        borderRadius: "100px",
        color: "black",
      }}
    >
      <Box
        sx={{
          width: isSmallScreen ? "12px" : "16px",
          height: isSmallScreen ? "12px" : "16px",
          color: "black",
          " svg": {
            color: "black",
            stroke: "black",
            width: isSmallScreen ? "12px" : "16px",
            height: isSmallScreen ? "12px" : "16px",
          },
          " svg path": {
            stroke: theme.palette.softBlack.light,
          },
        }}
      >
        <Icon />
      </Box>
      <Typography
        variant={isSmallScreen ? "caption" : "body1"}
        sx={{
          color: theme.palette.softBlack.light,
          whiteSpace: "nowrap",
          textTransform: "uppercase",
          fontSize: "8px",
        }}
      >
        {name}
      </Typography>
    </Box>
  );
}

function NutritionVerticalDivider(props: {}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "1px",
        height: "10px",
        borderLeft: "1px solid " + theme.palette.softBlack.light,
      }}
    ></Box>
  );
}
function HorizontalDivider(props: {}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        height: "1px",
        boxSizing: "border-box",
        borderBottom: "1px solid " + theme.palette.softBlack.light,
      }}
    ></Box>
  );
}

function decimalToCommonFraction(number: number) {
  const fractions = {
    "0.50": "½",
    "0.33": "⅓",
    "0.67": "⅔",
    "0.25": "¼",
    "0.75": "¾",
    "0.20": "⅕",
    "0.40": "⅖",
    "0.60": "⅗",
    "0.80": "⅘",
    "0.17": "⅙",
    "0.83": "⅚",
  } as any;

  const intPart = Math.floor(number);
  const decimalPart = (number - intPart).toFixed(2); // Round to 2 decimal places

  if (fractions[decimalPart]) {
    return `${intPart !== 0 ? intPart + " " : ""}${fractions[decimalPart]} `;
  }

  return Math.floor(number * 10) / 10; // Return original number if no common fraction is found
}
