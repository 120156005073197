import { ICardTeaserFields } from "../../../@types/generated/contentful";
import Card from "../../card";

interface CardSectionProps extends ICardTeaserFields {
  isInCarousel?: boolean;
}

/**
 * Wrapper for Card component to be displayed in content-section
 *
 * @param {CardSectionProps} props
 * @returns JSX.Element
 */
const SectionCardTeaser = (props: CardSectionProps) => {
  return <Card {...props} />;
};

export default SectionCardTeaser;
