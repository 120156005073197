import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import React from "react";

import { ITrustpilotSectionFields } from "../../../@types/generated/contentful";

const TrustpilotContentSection = (props: ITrustpilotSectionFields) => {
  const ref = React.useRef(null);
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { templateIdLarge, templateIdSmall, heightDesktop, heightMobile } =
    props;

  var templateId = onlySmallScreen ? templateIdSmall : templateIdLarge;

  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.

    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [templateId]);
  return (
    <>
      <div
        ref={ref}
        id="trustpilotTestimonials"
        className="trustpilot-widget"
        data-locale="de-DE"
        data-template-id={templateId}
        data-businessunit-id="646343d4cf21107bf0054c8c"
        data-style-height={`${
          onlySmallScreen ? heightMobile : heightDesktop
        }px`}
        data-style-width="100%"
        data-theme="dark"
        data-stars="5"
        data-review-languages="de"
        data-text-color={theme.palette.softBlack.main}
      >
        <a
          href="https://de.trustpilot.com/review/hellohealth.de"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    </>
  );
};

export default TrustpilotContentSection;
