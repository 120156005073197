"use client";

import { AddShoppingCart } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Image from "next/image";
import Link from "next/link";

import { useContext } from "react";

import { IProductTileFields } from "../../../@types/generated/contentful";
import useLink from "../../../hooks/useLink";
import { CartContext } from "../../../utils/contexts";
import { formatPrice, withHttps } from "../../../utils/helperFunctions";
import { IProductFieldsModified } from "../../../utils/shopify/createCart";
import WrapInLink from "../../link-wrap";
import RichTextRenderer from "../helper/richTextRenderer";

/**
 * Renders MUI Card with image, text and cta button
 */
const ProductTile = (productTileFields: IProductTileFields) => {
  const {
    image,
    title,
    description,
    price,
    strikethroughPrice,
    product,
    link,
    disabled,
    ribbonText,
    backgroundColor,
    ribbonColor,
    buttonText,
  } = productTileFields;
  const shopifyProduct = product
    ? (product?.fields as IProductFieldsModified).shopifyProduct
    : null;

  const { getLinkProps } = useLink();
  const theme = useTheme();
  const { setCartItemAmount, cart } = useContext(CartContext);

  const isSmallToMidScreen = useMediaQuery(
    theme.breakpoints.between("sm", "md")
  );
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const imageFields = image?.fields;

  const linkProps = link && getLinkProps(link.fields);
  const imageSize = 200;

  return (
    <Card
      sx={{
        boxSizing: "border-box",
        display: "flex",
        backgroundColor: backgroundColor,
        maxWidth: "1200px",
        width: "100%",
        alignItems: "center",
        flexDirection: isSmallToMidScreen ? "row" : "column",
        justifyContent: "space-between",
        height: "calc(100% - 16px)",
        p: theme.spacing(3),
        mb: theme.spacing(2),
        position: "relative",
        ...(linkProps
          ? {
              "&:hover h3": {
                color: theme.palette.primary.main,
              },
            }
          : {}),
      }}
    >
      {ribbonText && (
        <Box
          sx={{
            zIndex: 1,
            position: "absolute",

            top: "60px",
            left: "60px",
            width: "100%",
            transform: "translate(-50%,-50%) rotate(-45deg)",
            textAlign: "center",
            backgroundColor: ribbonColor,
            py: theme.spacing(0.5),
          }}
        >
          <Typography
            sx={{ fontWeight: 600, color: backgroundColor }}
            variant="body1"
          >
            {ribbonText}
          </Typography>
        </Box>
      )}
      {imageFields && (
        <WrapInLink linkFields={link && link.fields}>
          <CardMedia
            sx={{
              display: "flex",
              justifyContent: "center",
              objectFit: "contain",

              width: imageSize + "px",
              minWidth: isSmallToMidScreen ? imageSize + "px" : "none",
              height: imageSize + "px",
              position: "relative",
              mb: isSmallToMidScreen ? 0 : theme.spacing(3),
              mr: isSmallToMidScreen ? theme.spacing(3) : 0,
            }}
          >
            <Image
              src={withHttps(imageFields.file.url)}
              className="list-avatar"
              style={{
                objectFit: "contain",
              }}
              fill
              sizes={imageSize + "px"}
              alt={imageFields.description}
            />
          </CardMedia>
        </WrapInLink>
      )}
      <CardContent
        sx={{
          flex: 1,
          display: "flex",
          padding: 0,
          paddingTop: 0,
          flexDirection: "column",

          alignItems: "flex-start",
          justifyContent: "space-between",
          textDecoration: "none",
          width: "100%",
          height: "100%",

          "&:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        <Box sx={{ " a": { textDecoration: "none" } }}>
          {title && (
            <WrapInLink linkFields={link && link.fields}>
              <Typography
                variant="h3"
                component="h3"
                sx={{
                  mb: 2,
                  wordBreak: "break-word",
                  textAlign: "left",
                  transition: "color 0.2s ease-out",
                }}
              >
                {title}
              </Typography>
            </WrapInLink>
          )}

          {description && (
            <WrapInLink linkFields={link && link.fields}>
              <Typography
                sx={{
                  textDecoration: "none",
                  "& span": {
                    color: theme.palette.softBlack.main,
                  },
                }}
              >
                <RichTextRenderer richTextDocument={description} />
              </Typography>
            </WrapInLink>
          )}
        </Box>
        {linkProps && (
          <Typography
            sx={{
              "&:hover": {
                textDecoration: "underline",
                textDecorationColor: theme.palette.primary.main,
              },
            }}
          >
            <Link
              style={{
                color: theme.palette.primary.main,
                textDecoration: "none",
              }}
              href={linkProps?.href}
            >
              Mehr erfahren
            </Link>
          </Typography>
        )}

        {product && (
          <Box
            sx={{
              flex: 1,
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              mt: theme.spacing(3),
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                className="teaser-buy-button"
                onClick={() => {
                  if (product) {
                    const shopifyProduct = (
                      product?.fields as IProductFieldsModified
                    ).shopifyProduct;
                    const variantId = shopifyProduct.variantId;
                    const cartItem = cart.items[variantId] || {
                      variantId: shopifyProduct.variantId,
                      amount: 0,
                      product: shopifyProduct,
                    };
                    setCartItemAmount(cartItem, cartItem.amount + 1);
                  }
                }}
                disabled={!product || disabled}
                sx={{
                  fontSize: isSmallScreen ? "14px" : "16px",
                  " span": {
                    mr: theme.spacing(
                      buttonText && !disabled && product ? 2 : 0
                    ),
                  },
                }}
                id={"InternalButtonName"}
                color="primary"
                variant="contained"
                startIcon={!disabled && product ? <AddShoppingCart /> : null}
              >
                {buttonText ? buttonText : ""}
              </Button>

              <Box sx={{ ml: theme.spacing(2) }}>
                {product && (
                  <>
                    {strikethroughPrice && (
                      <Typography variant="body1" sx={{ height: "100%" }}>
                        <s>
                          {" "}
                          {formatPrice(
                            parseFloat(strikethroughPrice),
                            shopifyProduct?.price.currencyCode
                          )}
                        </s>
                      </Typography>
                    )}
                    <Typography variant="h4" sx={{ height: "100%" }}>
                      {price
                        ? formatPrice(
                            parseFloat(price),
                            shopifyProduct?.price.currencyCode
                          )
                        : formatPrice(
                            shopifyProduct?.price.amount,
                            shopifyProduct?.price.currencyCode
                          )}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
export default ProductTile;
