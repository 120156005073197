import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const Arrow = ({
  direction,
  clickFunction,
  glyph,
  isHovered,
  size = 48,
}: any) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        opacity: isHovered ? "1" : 0,
        transition: ".5s opacity ease-out, background-color 0.3s ease-out",
        position: "absolute",
        top: "0",
        left: direction === "left" ? "0px" : "unset",
        right: direction === "left" ? "unset" : "0px",
        height: "100%",
        width: "15%",
        "&:hover": { backgroundColor: "rgba(255,255,255,0.2)" },
        "&:hover .arrow": {
          opacity: 1,
        },
        zIndex: 9,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={clickFunction}
    >
      <Box
        className="arrow"
        sx={{
          borderRadius: "100%",
          backgroundColor: theme.components?.Arrow.backgroundColor,
          opacity: 0.8,
          width: `${size}px`,
          height: `${size}px`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "opacity 0.3s ease-in-out",
          cursor: "pointer",
        }}
      >
        {direction == "left" ? (
          <ArrowBackIos
            sx={{
              width: `${(size * 2) / 4}px`,
              height: `${(size * 2) / 4}px`,
              color: theme.components?.Arrow.color,
              marginLeft: `${size * 0.2}px`,
            }}
          >
            {glyph}
          </ArrowBackIos>
        ) : (
          <ArrowForwardIos
            sx={{
              width: `${(size * 2) / 4}px`,
              height: `${(size * 2) / 4}px`,
              color: theme.components?.Arrow.color,
              marginLeft: "2px",
            }}
          ></ArrowForwardIos>
        )}
      </Box>
    </Box>
  );
};
