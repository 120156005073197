import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import React from "react";

import { IVideoFields } from "../../../@types/generated/contentful";

const Video: React.FC<IVideoFields> = ({ altText, embedCode }) => {
  const theme = useTheme();
  const iframeStartPosition = embedCode.search(/<iframe/);
  const iframeEndPosition = embedCode.search(/<\/iframe/) + "</iframe>".length;

  const iframeCode = embedCode.slice(iframeStartPosition, iframeEndPosition);

  const containsIframe = iframeStartPosition !== -1 && iframeEndPosition !== 8;

  if (containsIframe) {
    const isVimeo = embedCode.match(
      '^[\\s]*<div style="padding:56.25% 0 0 0;position:relative;">\\s*<iframe src="(?:http:|https:|)//(?:player.|www.)?vimeo.com/(?:video/|embed/|watch?)?.*>\\s*</iframe>\\s*</div>\\s*<script src="https://player.vimeo.com/api/player.js">\\s*</script>[\\s]*$'
    );
    const isSpotify = /spotify\.com/.test(embedCode);
    const isPodcastProduzenten = /podcastproduzenten\.de/.test(embedCode);
    return isVimeo
      ? VimeoVideo({ iframeCode })
      : isPodcastProduzenten
      ? PodcastEmbed({ iframeCode, altText })
      : isSpotify
      ? SpotifyEmbed({ iframeCode, altText })
      : YoutubeVideo({ iframeCode, altText });
  }
  return (
    <Box>
      <Typography variant="body1">{altText}</Typography>
    </Box>
  );
};

const VimeoVideo: React.FC<{ iframeCode: string }> = ({ iframeCode }) => {
  const theme = useTheme();
  return (
    <Box
      dangerouslySetInnerHTML={{
        __html: iframeCode,
      }}
      sx={{
        position: "relative",
        borderRadius: theme.components?.Video.borderRadius,
        padding: "56.25% 0 0 0",
        overflow: "hidden",
      }}
    />
  );
};

const YoutubeVideo: React.FC<{ iframeCode: string; altText: string }> = ({
  iframeCode,
  altText,
}) => {
  const theme = useTheme();
  const pattern = /src="https:\/\/www\.youtube\.com\/embed\/([^\/\?\&]+)/;

  const match = iframeCode.match(pattern);
  if (match && match[1]) {
    const youtubeId = match[1];
    return (
      <div
        className="video"
        style={{
          position: "relative",
          paddingBottom: "56.25%" /* 16:9 */,
          height: 0,
          borderRadius: theme.components?.Video.borderRadius,
          overflow: "hidden",
        }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          src={`https://www.youtube.com/embed/${youtubeId}?controls=0`}
          frameBorder="0"
        />
      </div>
    );
  }
  return (
    <Box>
      <Typography variant="body1">{altText}</Typography>
    </Box>
  );
};
const PodcastEmbed: React.FC<{ iframeCode: string; altText: string }> = ({
  iframeCode,
}) => {
  const theme = useTheme();

  return (
    <Box
      dangerouslySetInnerHTML={{
        __html: iframeCode,
      }}
      sx={{
        position: "relative",
        borderRadius: theme.components?.Video.borderRadius,
        overflow: "hidden",
        height: "230px",
      }}
    />
  );
};
const SpotifyEmbed: React.FC<{ iframeCode: string; altText: string }> = ({
  iframeCode,
}) => {
  const theme = useTheme();

  return (
    <Box
      dangerouslySetInnerHTML={{
        __html: iframeCode,
      }}
      sx={{
        position: "relative",
        borderRadius: theme.components?.Video.borderRadius,
        overflow: "hidden",
      }}
    />
  );
};

export default Video;
