import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Asset } from "contentful";

import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import { Arrow } from "../arrow";
import { PreviewImage } from "../preview-image";

export const PreviewImageRow = (props: {
  imageList: Asset[];
  setCurrentIndex: Dispatch<SetStateAction<number>>;
  currentIndex: number;
  imageAmount: number;
}) => {
  const { imageList, setCurrentIndex, currentIndex, imageAmount } = props;
  const theme = useTheme();
  const [xOffset, setXOffset] = useState(0);
  const innerBox = useRef<HTMLDivElement>(null);
  const outerBox = useRef<HTMLDivElement>(null);
  const arrowSize = 30;

  const getMaxOffset = () => {
    if (innerBox.current != null && outerBox.current != null) {
      let innerRect = innerBox.current.getBoundingClientRect();
      let outerRect = outerBox.current.getBoundingClientRect();
      return innerRect.width <= outerRect.width
        ? 0
        : (innerRect.width - outerRect.width) / innerRect.width;
    }
    return 0;
  };

  useEffect(() => {
    let previewXOffset = currentIndex / imageAmount;
    if (innerBox.current != null && outerBox.current != null) {
      let innerRect = innerBox.current.getBoundingClientRect();
      let outerRect = outerBox.current.getBoundingClientRect();
      //add half of the outer divs width and substract half an images' width to center the currently selected
      previewXOffset -=
        (outerRect.width * 0.5) / innerRect.width - 0.5 / imageAmount;
    }
    setNewOffset(previewXOffset);
  }, [innerBox, outerBox, currentIndex]);

  const setNewOffset = (newXOffset: number) => {
    const maxOffset = getMaxOffset();
    setXOffset(Math.max(0, Math.min(maxOffset, newXOffset) * 100));
  };

  const moveForward = () => {
    const newOffset = xOffset / 100 + 1 / imageAmount;
    setNewOffset(newOffset);
  };

  const moveBackward = () => {
    const newOffset = xOffset / 100 - 1 / imageAmount;
    setNewOffset(newOffset);
  };

  return (
    <Box
      ref={outerBox}
      sx={{
        overflow: "hidden",
        marginTop: theme.spacing(2),
        position: "relative",
      }}
    >
      {xOffset !== 0 && (
        <Arrow
          direction="left"
          clickFunction={moveBackward}
          glyph="&#9664;"
          isHovered={true}
          size={arrowSize}
        />
      )}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9,
          pointerEvents: "none",
          boxShadow: "inset 0px 0px 5px 3px white",
        }}
      />
      <Box
        ref={innerBox}
        sx={{
          display: "flex",
          minWidth: "min-content",

          transition: ".4s transform ease-out",
          transform: `translateX(-${xOffset}%)`,
        }}
      >
        {imageList.map((image, i) => (
          <PreviewImage
            key={"imageSlidePreview" + i}
            onclick={() => setCurrentIndex(i)}
            src={image.fields.file.url}
            isSelected={i == currentIndex}
          />
        ))}
      </Box>
      {xOffset < getMaxOffset() * 100 && (
        <Arrow
          direction="right"
          clickFunction={moveForward}
          glyph="&#9664;"
          isHovered={true}
          size={arrowSize}
        />
      )}
    </Box>
  );
};
