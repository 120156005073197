"use client";

import { ArrowBackIos, ArrowForwardIos, Pages } from "@mui/icons-material";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useEmblaCarousel from "embla-carousel-react";

import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import { ICardCarouselFields } from "../../../@types/generated/contentful";
import { ContentSectionThemeContext } from "../../../utils/contexts";
import RichTextRenderer from "../helper/richTextRenderer";
import SectionCardTeaser from "../section-card";
import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from "./EmblaCarouselArrowButtons";
import { DotButton, useDotButton } from "./EmblaCarouselDotButtons";

const useResponsivePageSize = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const isMidScreen = useMediaQuery(theme.breakpoints.only("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  // Calculate pageSize based on screen size
  const calculatePageSize = () =>
    isSmallScreen ? 1 : isMidScreen ? 1 : isLargeScreen ? 3 : 2;

  const [pageSize, setPageSize] = useState(calculatePageSize);
  useEffect(() => {
    // Update pageSize on screen size change
    const updatePageSize = () => {
      setPageSize(calculatePageSize());
    };
    updatePageSize();
    // Listen for window resize events to handle dynamic screen size changes
    window.addEventListener("resize", updatePageSize);

    // Cleanup listener
    return () => window.removeEventListener("resize", updatePageSize);
  }, [isSmallScreen, isMidScreen, isLargeScreen]);

  return pageSize;
};

const CardCarousel = ({
  heading,
  fullWidth = false,
  level,
  cards,
  introText,
  internal,
  backgroundColor,
}: ICardCarouselFields) => {
  const theme = useTheme();
  const sectionTheme = useContext(ContentSectionThemeContext);
  const pageSize = useResponsivePageSize();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: pageSize,
  });
  cards = cards || [];

  const Variants = {
    H1: "h1",
    H2: "h2",
    H3: "h3",
  } as const;

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  useEffect(() => {
    // Update Embla Carousel's slidesToScroll when pageSize changes
    if (emblaApi) {
      emblaApi.reInit({
        slidesToScroll: pageSize,
      });
    }
  }, [pageSize, emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.reInit({
        slidesToScroll: pageSize,
        active: scrollSnaps.length > 1,
      });
    }
  }, [scrollSnaps.length]);

  const internalId = (internal || "").replace(/\s/g, "");
  return (
    <>
      <div
        style={{
          opacity: 0,
          marginTop: "-80px",
          position: "absolute",
          top: "-80px",
        }}
        id={internalId}
      ></div>
      {(heading || introText) && (
        <Grid
          container
          sx={{
            mx: "auto",
            marginBottom: cards.length ? theme.spacing(4) : 0,
            [theme.breakpoints.up("sm")]: {
              justifyContent: "center",
              textAlign: "center",
              maxWidth: "66.6%",
            },
          }}
        >
          {heading && (
            <Typography
              color={sectionTheme.textColor}
              variant={(level && Variants[level]) || "h2"}
              component={(level && Variants[level]) || "h2"}
            >
              {heading}
            </Typography>
          )}
          {introText && (
            <Box sx={{ textAlign: "left", mt: 3 }}>
              <RichTextRenderer richTextDocument={introText} />
            </Box>
          )}
        </Grid>
      )}
      {!!cards.length && (
        <Box
          className="embla"
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
            position: "relative",
            flexWrap: "nowrap",
            "&::before": {
              background: `linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, ${backgroundColor} 100%)`,
              content: '""',
              width: "20px",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
            },
            "&::after": {
              background: `linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, ${backgroundColor} 100%)`,
              content: '""',
              width: "20px",
              height: "100%",
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 1,
            },
          }}
          ref={emblaRef}
        >
          <Box
            className="embla__container"
            sx={{
              display: "flex",
            }}
          >
            {cards?.map((card, index) => (
              <Box
                key={`card${index}`}
                sx={{
                  flex: "1 0",
                  width: {
                    xs: "90%",
                    sm: "90%",
                    md: 95 / 2 + "%",
                    lg: 95 / 3 + "%",
                  },
                  minWidth: {
                    xs: "90%",
                    sm: "90%",
                    md: 95 / 2 + "%",
                    lg: 95 / 3 + "%",
                  },
                  px: { xs: theme.spacing(1), sm: theme.spacing(2) },
                  pt: { xs: 0, sm: theme.spacing(2) },
                  boxSizing: "border-box",
                }}
              >
                <SectionCardTeaser
                  {...card.fields}
                  isInCarousel={true}
                ></SectionCardTeaser>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {scrollSnaps.length > 1 && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: theme.spacing(2),
            marginTop: theme.spacing(4),
          }}
        >
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />

          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              selected={index === selectedIndex}
            />
          ))}

          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </Box>
      )}
    </>
  );
};

export default CardCarousel;
