import Link from "next/link";

import { ILinkFields } from "../../@types/generated/contentful";
import useLink from "../../hooks/useLink";

interface WrapInLinkProps {
  linkFields?: ILinkFields;
  children: React.ReactNode;
}

const WrapInLink = ({ children, linkFields }: WrapInLinkProps) => {
  const { getLinkProps } = useLink();
  const linkProps = linkFields && getLinkProps(linkFields);
  return linkProps ? (
    <Link href={linkProps.href} target={linkProps.linkTarget}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};
export default WrapInLink;
