import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useContext } from "react";

import { IMainHeadingFields } from "../../../@types/generated/contentful";
import { ContentSectionThemeContext } from "../../../utils/contexts";
import { getIdFromString } from "../../../utils/helperFunctions";
import IdAnchor from "../../id-anchor";

/**
 * section heading component for the main-heading content-type
 * @param {IMainHeadingFields} props
 * @returns JSX.Element
 */
const SectionMainHeading = ({
  internal,
  heading,
  level,
}: IMainHeadingFields) => {
  const context = useContext(ContentSectionThemeContext);
  const theme = useTheme();
  const Variants = {
    H1: "h1",
    H2: "h2",
    H3: "h3",
  } as const;

  return (
    <>
      <Typography
        color={context.textColor}
        id={internal}
        variant={Variants[level]}
        component={Variants[level]}
        sx={{
          [theme.breakpoints.up("sm")]: {
            textAlign: "center",
          },
        }}
      >
        <IdAnchor id={getIdFromString(internal)} />
        {heading}
      </Typography>
    </>
  );
};

export default SectionMainHeading;
