"use client";

import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Image from "next/image";
import Link from "next/link";

import { useContext } from "react";

import { IListCardFields } from "../../../@types/generated/contentful";
import useContentSectionThemeContext from "../../../hooks/useContentSectionThemeContext";
import useLink from "../../../hooks/useLink";
import { ContentSectionThemeContext } from "../../../utils/contexts";
import { withHttps } from "../../../utils/helperFunctions";
import CTAButton from "../../cta-button";
import WrapInLink from "../../link-wrap";
import RichTextRenderer from "../helper/richTextRenderer";

/**
 * Renders MUI Card with image, text and cta button
 */
const CardListElement = (listCardProps: IListCardFields) => {
  const { image, heading, text, backgroundColor, link, button } = listCardProps;
  const { getLinkProps } = useLink();
  const theme = useTheme();
  const itemTextColor =
    useContentSectionThemeContext(backgroundColor).textColor;
  const sectionTextColor = useContext(ContentSectionThemeContext).textColor;
  const isBackgroundSet = backgroundColor && backgroundColor !== "none";
  const textColor = isBackgroundSet ? itemTextColor : sectionTextColor;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const imageFields = image?.fields.image.fields;
  const isPortrait =
    imageFields &&
    imageFields.file.details.image &&
    imageFields.file.details.image?.width <
      imageFields.file.details.image?.height;
  const linkProps = link && getLinkProps(link.fields);
  const imageSize = 200;

  return (
    <Card
      sx={{
        boxSizing: "border-box",
        display: "flex",
        maxWidth: "1200px",
        width: "100%",
        alignItems: "stretch",
        flexDirection: isSmallScreen ? "column" : "row",
        justifyContent: "flex-start",
        p: theme.spacing(2),
        boxShadow: "none",
        backgroundColor:
          backgroundColor === "none" ? "transparent" : backgroundColor,
        ...(linkProps
          ? {
              "& a": {
                textDecoration: "none",
              },
              "&:hover h3": {
                color: theme.palette.darkGreige.dark,
              },
            }
          : {}),
      }}
    >
      {imageFields && (
        <WrapInLink linkFields={link && link.fields}>
          <CardMedia
            sx={{
              display: "flex",
              alignSelf: "flex-start",
              objectFit: "contain",
              mr: theme.spacing(2),
            }}
          >
            <Image
              width={200}
              height={200}
              sizes={"200px"}
              style={{
                width: isPortrait ? "auto" : `${imageSize}px`,
                height: !isPortrait ? "auto" : `${imageSize}px`,
                marginLeft: "auto",
                marginRight: isSmallScreen ? "auto" : theme.spacing(2),
                marginBottom: isSmallScreen ? theme.spacing(3) : 0,
              }}
              src={withHttps(imageFields.file.url)}
              alt={image.fields.altText}
            />
          </CardMedia>
        </WrapInLink>
      )}
      <CardContent
        sx={{
          display: "flex",
          padding: 0,
          paddingTop: 0,
          flexDirection: "column",

          alignItems: "flex-start",
          justifyContent: "flex-start",
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        {heading && (
          <WrapInLink linkFields={link && link?.fields}>
            <Typography
              variant="h3"
              component="h3"
              sx={{
                color: textColor,
                mb: 2,
                wordBreak: "break-word",
                textAlign: "left",
                transition: "color 0.2s ease-out",
              }}
            >
              {heading}
            </Typography>
          </WrapInLink>
        )}

        {text && (
          <Typography
            sx={{
              "& span": {
                color: textColor,
              },
            }}
          >
            <RichTextRenderer richTextDocument={text} />
          </Typography>
        )}

        {button && (
          <Box
            sx={{
              mt: 1,
            }}
          >
            <CTAButton {...button.fields} backgroundColor={backgroundColor} />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
export default CardListElement;
