import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Image from "next/image";

import { withHttps } from "../../../../utils/helperFunctions";

export const PreviewImage = ({
  src,
  onclick,
  isSelected,
}: {
  src: string;
  onclick: () => void;
  isSelected: boolean;
}) => {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMidScreen =
    useMediaQuery(theme.breakpoints.down("md")) && !onlySmallScreen;
  return (
    <Box
      sx={{
        overflow: "hidden",
        margin: theme.spacing(2),
        borderRadius: "5px",
        cursor: "pointer",
        transition: ".3s box-shadow ease-out, .3s transform linear 0.1s",

        width: { xs: "70px", sm: "90px", md: "118px" },
        height: { xs: "70px", sm: "90px", md: "118px" },
        boxShadow: isSelected
          ? `0px 0px 0px 1px ${theme.palette.orange.dark}!important`
          : `0px 0px 0px 1px ${theme.palette.softBlack.light}`,
        transform: isSelected ? "scale(1.15)" : "scale(1)",
        "&:hover": {
          boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main}`,
        },
      }}
    >
      <Image
        style={{ objectFit: "contain" }}
        className="image-slide"
        priority={true}
        width={onlyMidScreen ? 90 : onlySmallScreen ? 70 : 118}
        height={onlyMidScreen ? 90 : onlySmallScreen ? 70 : 118}
        src={withHttps(src)}
        alt={""}
        onClick={onclick}
        sizes={`(max-width: 600px) 70px, 
          (max-width: 600px) 90px
          118px`}
      />
    </Box>
  );
};
