import { useTheme } from "@mui/material/styles";

import Image from "next/legacy/image";
import Link from "next/link";

import { IImageFields } from "../../../@types/generated/contentful";
import { withHttps } from "../../../utils/helperFunctions";

/**
 * We want to rely on the autogenerated IImageFields type from Contentful.
 * However, for displaying just the image, we need neither the internal name,
 * nor the link field.
 * However, we need the option to render the image unoptimized (a prop for Next.js's
 * Image component meaning it doesn't get internally optimized by Next. Internal
 * optimization clashes with Cypress' rendering).
 */
interface SectionImageElementProps extends IImageFields {
  unoptimized?: boolean;
  colspan?: number;
}
/**
 * We need the link field here, so omit internal only.
 * @see SectionImageElementProps
 */
interface SectionImageProps extends IImageFields {
  unoptimized?: boolean;
  colspan?: number;
}

const SectionImageElement = (props: SectionImageElementProps) => {
  const { image, altText, internal, unoptimized, colspan = 12 } = props;

  const { url } = image.fields.file;

  const dimensions = image.fields.file.details?.image || {
    width: 100,
    height: 100,
  };
  const { width, height } = dimensions;

  const theme = useTheme();

  return (
    <Image
      id={internal}
      src={withHttps(url)}
      sizes={`(max-width: 900px) 100vw,
        (max-width: 1200px) ${colspan === 12 ? 66 : 100 / (colspan / 12)}vw,
              ${1200 / (colspan / 12)}px`}
      layout="responsive"
      width={width}
      height={height}
      alt={altText}
      unoptimized={unoptimized || false}
      style={{ borderRadius: theme.components?.Image.borderRadius }}
    />
  );
};

/**
 * section image displays a picture, with the option to link it, as well
 *
 * @param {IImageFields} props
 * @returns JSX.Element
 */
const SectionImage = ({
  image,
  internal,
  altText,
  link,
  unoptimized,
  colspan,
}: SectionImageProps) => {
  if (link) {
    return (
      <Link href={link}>
        <SectionImageElement
          image={image}
          internal={internal}
          altText={altText}
          unoptimized={unoptimized}
          colspan={colspan}
        />
      </Link>
    );
  }

  return (
    <SectionImageElement
      image={image}
      internal={internal}
      altText={altText}
      unoptimized={unoptimized}
      colspan={colspan}
    />
  );
};

export default SectionImage;
