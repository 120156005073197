import { ICtaButtonFields } from "../../../@types/generated/contentful";
import CTAButton from "../../cta-button";

type SectionButtonProps = ICtaButtonFields;

/** Section button component for the ctaButton content of contentful
 *
 * @param {SectionButtonProps} props
 * @returns JSX.Element
 */
const SectionButton = ({ ...props }: SectionButtonProps) => {
  return <CTAButton {...props} />;
};

export default SectionButton;
