import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const ImageSelectionMinimap = (props: {
  imageAmount: number;
  currentIndex: number;
  setCurrentIndex: (i: number) => void;
  height: number;
}) => {
  const { currentIndex, imageAmount, height, setCurrentIndex } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "20px",
        position: "absolute",
        top: `${height}px`,
        left: 0,
        overflow: "visible",
        zIndex: 9,
        marginTop: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {Array(imageAmount)
        .fill(0)
        .map((_, i) => (
          <div key={"imageMinimap" + i} onClick={() => setCurrentIndex(i)}>
            <Box
              sx={{
                cursor: "pointer",
                mx: theme.spacing(0.5),
                width: "9px",
                height: "9px",
                transition: ".5s background-color ease-in-out",
                background:
                  currentIndex == i
                    ? theme.palette.secondary.main
                    : "transparent",
                border: `2px solid ${theme.palette.secondary.main}`,
                borderRadius: theme.components?.Image.borderRadius,
              }}
            />
          </div>
        ))}
    </Box>
  );
};
