"use client";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import React, { useContext, useEffect } from "react";

import { IHubspotFormFields } from "../../../@types/generated/contentful";
import { TenantContext } from "../../../utils/contexts";
import RichTextRenderer from "../helper/richTextRenderer";

const SectionHubspotForm = ({
  formSnippet,
  text,
  ...rest
}: IHubspotFormFields) => {
  const theme = useTheme();
  theme.components;
  const pattern =
    /region:\s*['"](\w+)['"],\s*portalId:\s*['"](\d+)['"],\s*formId:\s*['"]([\w-]+)['"]/;

  const match = pattern.exec(formSnippet);
  const targetElementId = `hubspotFormContainer${rest.internal.replace(
    /\s/g,
    ""
  )}`;
  const tenantContext = useContext(TenantContext);
  useEffect(() => {
    const createHubspotForm = () => {
      if (match) {
        const [, region, portalId, formId] = match;
        if (window.hbspt) {
          window.hbspt.forms.create({
            region,
            portalId,
            formId,
            target: `#${targetElementId}`,
            translations: {
              de: {
                required: "Bitte fülle dieses Pflichtfeld aus.",
              },
            },
          });
        }
      }
    };

    const checkForHbspt = () => {
      if (window.hbspt) {
        createHubspotForm();
      } else {
        setTimeout(checkForHbspt, 100);
      }
    };

    checkForHbspt();
  }, [match, targetElementId]);
  return (
    <Box className={tenantContext.tenant}>
      {text && (
        <Box sx={{ mb: 3, mt: -1 }} className={"section-text-container"}>
          <RichTextRenderer richTextDocument={text}></RichTextRenderer>
        </Box>
      )}
      <div className="hubspotFormContainer" id={targetElementId}></div>
    </Box>
  );
};

export default SectionHubspotForm;
