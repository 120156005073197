"use client";

import { styled } from "@mui/material/styles";

import { ITextFields } from "../../../@types/generated/contentful";
import RichTextRenderer from "../helper/richTextRenderer";

const StyledSectionText = styled("div")(() => ({}));

/**
 * section text component to display the RTE content from contentful
 * @param {ITextFields} props
 * @param {Document} props.content
 * @returns JSX.Element
 */
const SectionText = ({ content, internal, isSmallText }: ITextFields) => {
  return (
    <StyledSectionText
      sx={isSmallText ? { "*": { fontSize: "12px" } } : {}}
      id={internal}
      className="section-text-container"
    >
      <RichTextRenderer richTextDocument={content} />
    </StyledSectionText>
  );
};

export default SectionText;
