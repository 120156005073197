"use client";

import {
  Box,
  CardActions,
  CardContent,
  CardMedia,
  Card as MUICard,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import Image from "next/image";

import { ICardTeaserFields } from "../../@types/generated/contentful";
import useContentSectionThemeContext from "../../hooks/useContentSectionThemeContext";
import { withHttps } from "../../utils/helperFunctions";
import Badge from "../badge";
import CoachAvatar from "../coach-avatar";
import RichTextRenderer from "../content-section/helper/richTextRenderer";
import CTAButton from "../cta-button";

interface ICardProps extends ICardTeaserFields {
  isInCarousel?: boolean;
}

interface RooflineProps {
  isFreigestellt: boolean;
}
const Roofline = styled("span")<RooflineProps>((props) => ({
  textTransform: "uppercase",
  fontSize: 14,
  fontFamily: props.theme.typography.fontFamily,
  color: props.theme.palette.darkGreen.dark,
  marginBottom: props.theme.spacing(props.isFreigestellt ? 2 : 1),
  marginTop: props.isFreigestellt ? props.theme.spacing(3) : 0,
  display: "block",
}));

/**
 * Renders MUI Card with image, text and cta button
 */
const Card = ({
  button,
  heading,
  image,
  text,
  roofline,
  coachAvatar,
  badge,
  backgroundColor,
  isFreigestellt,
  //On xs screens within a carousel the cards don't fill the entire width, so some dimensions have to be adjusted
  isInCarousel,
}: ICardProps) => {
  const theme = useTheme();
  const { spacing } = theme;
  const colors = theme.palette;

  const textColor = useContentSectionThemeContext(backgroundColor).textColor;
  const BottomCircle = getBottomCircle(isInCarousel || false);
  return (
    <MUICard
      sx={{
        backgroundColor:
          backgroundColor === "none" ? "transparent" : backgroundColor,
        height: "100%",
        display: "flex",
        boxSizing: "border-box",
        flexDirection: {
          xs: "column",
          sm: "row",
          md: "column",
        },
        boxShadow: "none",
      }}
    >
      {image && (
        <CardMedia
          sx={{
            height: { xs: isInCarousel ? "190px" : "270px", sm: "270px" },
            width: "100%",
            flexShrink: 1,
            [theme.breakpoints.between("sm", "md")]: {
              height: "100%",
              width: "200px",
              flexShrink: 0,
            },
            backgroundPositionY: "top",
            position: "relative",
            overflow: "hidden",
            boxSizing: "border-box",
            p: 3,
            pb: 0,
          }}
          title={image.fields.altText}
        >
          <Image
            src={withHttps(image.fields.image.fields.file.url)}
            alt={image.fields.altText}
            fill
            sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
            style={{
              pointerEvents: "none",
              zIndex: 0,
              objectFit: isFreigestellt ? "contain" : "cover",
              objectPosition: isFreigestellt ? "middle" : "top",
            }}
          />
          {badge && <Badge {...badge.fields} />}
          {!isFreigestellt && (
            <BottomCircle
              className={"botCirc"}
              sx={{
                backgroundColor:
                  backgroundColor === "none" ? "transparent" : backgroundColor,
              }}
            />
          )}
        </CardMedia>
      )}
      <CardContent
        sx={{
          p: { xs: isInCarousel ? 2 : 4, sm: 4 },
          py: 0,
          "&:last-child": {
            pb: {
              xs: image ? 0 : 4,
              sm: 4,
              md: image ? 0 : 4,
            },
          },
          pt: {
            xs: image ? 1 : 4,
            sm: 4,
            md: image ? 1 : 4,
          },
          pb: {
            xs: image ? 0 : 4,
            sm: 4,
            md: image ? 0 : 4,
          },
          flexGrow: "1",
          ...(isFreigestellt
            ? { display: "flex", flexDirection: "column", alignItems: "center" }
            : {}),
        }}
      >
        {roofline && (
          <Roofline isFreigestellt={!!isFreigestellt}>{roofline}</Roofline>
        )}
        {heading && (
          <Typography
            sx={{ mb: coachAvatar ? 3 : 2, color: textColor }}
            variant="h3"
            component="div"
          >
            {heading}
          </Typography>
        )}
        {text && (
          <Typography
            sx={{
              mb: coachAvatar || button ? 2 : 0,
              ...(isFreigestellt
                ? {
                    textAlign: "center",
                    " span:last-child": {
                      mb: 0,
                    },
                  }
                : {}),
            }}
            component="div"
            variant="body1"
            color={textColor}
          >
            <RichTextRenderer richTextDocument={text} />
          </Typography>
        )}
        {coachAvatar && (
          <CoachAvatar coachAvatar={coachAvatar} textColor={textColor} />
        )}
        {button && (
          <Box
            sx={{
              mt: coachAvatar ? 4 : 0,
              display: {
                xs: "none",
                sm: isFreigestellt ? "flex" : "block",
                md: "none",
              },
              justifyContent: "center",
            }}
          >
            <CTAButton {...button.fields} />
          </Box>
        )}
      </CardContent>
      {button && (
        <CardActions
          sx={{
            p: { xs: isInCarousel ? 2 : 4, sm: 4 },
            pb: { xs: 4 },
            pt: { xs: coachAvatar ? 3 : 0 },
            display: {
              xs: isFreigestellt ? "flex" : "block",
              sm: "none",
              md: isFreigestellt ? "flex" : "block",
            },
            ...(isFreigestellt ? { justifyContent: "center" } : {}),
          }}
        >
          <CTAButton {...button.fields} />
        </CardActions>
      )}
    </MUICard>
  );
};
export default Card;
function getBottomCircle(isInCarousel: boolean) {
  return styled("div")(({ theme }) => {
    return {
      width: "300%",
      height: "300%",
      position: "absolute",
      top: 0,
      left: "50%",
      transform: "translate(-50%, 245px)",
      [theme.breakpoints.between("sm", "md")]: {
        top: "50%",
        left: 0,
        transform: "translate(180px, -50%)",
      },
      [theme.breakpoints.down("sm")]: isInCarousel
        ? {
            top: 0,
            left: "50%",
            transform: "translate(-50%, 160px)",
          }
        : {},
      borderRadius: theme.components?.Card.borderRadius == 0 ? 0 : "100%",
    };
  });
}
