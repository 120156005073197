"use client";

import { Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";

import { useContext } from "react";

import {
  IContentSectionFields,
  IQuoteSectionFields,
} from "../../../@types/generated/contentful";
import QuoteMarksIcon from "../../../components/icons/quoteMarks.svg";
import { ContentSectionThemeContext } from "../../../utils/contexts";
import CoachAvatar from "../../coach-avatar";
import RichTextRenderer from "../helper/richTextRenderer";

const StyledSectionText = styled("div")(() => ({}));

interface QuoteSectionProps extends IQuoteSectionFields {
  layout: IContentSectionFields["layout"];
  itemAmount: number;
}

/**
 * section text component to display the RTE content from contentful
 * @param {IQuoteSectionFields} props
 * @param {Document} props.content
 * @returns JSX.Element
 */
const SectionQuote = ({
  content,
  authorName,
  authorSubtext,
  layout,
  itemAmount,
  coachAvatar,
  color,
}: QuoteSectionProps) => {
  const theme = useTheme();
  const sectionTheme = useContext(ContentSectionThemeContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isBackgroundSet, textColor } = useContext(ContentSectionThemeContext);
  const isSoleElement = layout === "1" && itemAmount == 1;
  let size = isSmallScreen ? 60 : 80;
  if (!isSoleElement || !isBackgroundSet) {
    size = isSmallScreen ? 30 : 50;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          //If element is within 1-column and there are other elements in the content section, create some space at the top for the quotation marks
          pt: !isSoleElement
            ? { xs: theme.spacing(8), md: theme.spacing(12) }
            : theme.spacing(0),
        }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: 2,
            top: !isSoleElement
              ? { xs: "-2px", md: "14px" }
              : isBackgroundSet
              ? { xs: "-70px", md: "-120px" }
              : {
                  xs: theme.spacing(-4 - (size * 0.5) / 8),
                  md: theme.spacing(-4 - size / 8),
                },
            left: isBackgroundSet ? theme.spacing(4) : "50%",
            width: size,
            height: size,
          }}
        >
          <QuoteMarksIcon
            style={{
              transform: isBackgroundSet ? "none" : "translate(-50%,0)",
              zIndex: 2,
              width: size,
              height: size,
              minWidth: size,
              minHeight: size,
              fill: color,
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            textAlign: "center",
          }}
        >
          <StyledSectionText className="quote-text-container">
            <RichTextRenderer richTextDocument={content} />
          </StyledSectionText>
        </Box>
        {(coachAvatar || authorName || authorSubtext) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: theme.spacing(4),
              mb: !isSoleElement ? theme.spacing(4) : 0,
              textAlign: "left",
            }}
          >
            {!coachAvatar && (authorName || authorSubtext) && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: sectionTheme.textColor,
                    fontWeight: "bold",
                  }}
                >
                  {authorName}
                </Typography>
                {authorSubtext && (
                  <Typography
                    sx={{ color: sectionTheme.textColor }}
                    variant="body1"
                  >
                    {authorSubtext}
                  </Typography>
                )}
              </Box>
            )}

            {coachAvatar && (
              <CoachAvatar coachAvatar={coachAvatar}></CoachAvatar>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default SectionQuote;
